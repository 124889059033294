import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { IntlActions } from 'react-redux-multilingual'
import Pace from 'react-pace-progress'

// Import custom components
import store from '../../../store';
import { connect } from "react-redux";
import LogoImage from "./common/logo";
import NavBarHome from "./common/navbarhome";
import { changeCurrency } from '../../../actions';
import CartContainer from "./../../../containers/CartContainer";

import MyTabDetailsProduts from '../../products/mySingleProduct/my-tab-details-products';

//icons
import person from "../../../assets/images/icon/person.png";
import langue from "../../../assets/images/icon/langue.png"

class HeaderHome extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    }
  }

  /*=====================
       Pre loader
       ==========================*/
  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  // componentWillMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  handleScroll = () => {
    let number = window.pageXOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (number >= 30) {
      if (window.innerWidth < 576) {
        document.getElementById("sticky-header").classList.remove('fixed');
      } else
        document.getElementById("sticky-header").classList.add('fixed');
    } else {
      document.getElementById("sticky-header").classList.remove('fixed');
    }
  }

  // changeLanguage(lang) {
  //   store.dispatch(IntlActions.setLocale(lang))
  // }

  // openNav() {
  //   console.log('open')
  //   this.setState({ navClose: { left: '0px' } })
  // }

  openNav() {
    var openmyslide = document.getElementById("mySidenav");
    console.log(openmyslide)
    if (openmyslide) {
      console.log('ouvert')
      openmyslide.classList.add('open-side')
    }
  }

  closeNav() {
    var closemyslide = document.getElementById("mySidenav");
    console.log(closemyslide)
    console.log('sorry ne peu pas fermer')
    if (closemyslide.classList.contains('open-side'))
      closemyslide.classList.remove('open-side');
    console.log('great peu fermer')
  }
  userdrop() {
    var opentoggle = document.getElementById("exit2");
    console.log(opentoggle)
    if (opentoggle)
      opentoggle.classList.toggle('exit3');
    console.log('hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh')

  }

  closeLink() {
    var closeLink = document.getElementById("main-nav-center");
    console.log(closeLink)
    console.log('sorry ne peu pas fermer link')
    if (closeLink)
      closeLink.classList.remove('open-side');
    document.querySelector("header-links").add('exit')
    console.log('great peu fermer link')
  }

  //   $(window).scroll(function(){
  //     if($(this).scrollTop() > 100){
  //         $('.nav').addClass('sticky')
  //     } else{
  //         $('.nav').removeClass('sticky')
  //     }
  // });




  // openSearch() {
  //   document.getElementById("search-overlay").style.display = "block";
  // }

  // closeSearch() {
  //   document.getElementById("search-overlay").style.display = "none";
  // }
  // openSearch1() {
  //   console.log(document.querySelector(".exit"))
  //   document.querySelector(".exit").style.display = "block !important";

  // }
  // closeSearch1() {
  //   console.log(document.querySelector("fa fa-user-o"))
  //   document.querySelector(".exit").style.display = "block !important";
  // }

  // doperclose() {
  //   if (document.querySelector(".enlever").classList.contains('remove')) {
  //     document.querySelector(".enlever").classList.add('add');
  //   }
  //   document.querySelector(".enlever").classList.remove('add');
  // }
  // doperclose2() {
  //   document.querySelector(".doper").classList.toggle('remove')
  // }

  load = () => {
    this.setState({ isLoading: true });
    fetch().then(() => {
      // deal with data fetched
      this.setState({ isLoading: false })
    })
  };

  render() {
    const { product } = this.props


    return (

      <div className='page-header test'>
        <div className='container standrd_menu'>
          <div className='top-header2 header content p-0 desktop-header d-lg-block'>
            <div className='columns row'>
              <div className='column4 col-sm-3 col-lg-3 mobile_logo'>
                <LogoImage logo={this.props.logoName} />
              </div>
              <div className='column4 col-sm-5 col-lg-6 flex_desktop'>
                <div className='search-area'>
                  <div className='block block-search'>
                    <div className='block block-title'>
                      <strong>Chercher</strong>
                    </div>
                    <div className='block block-content'>
                      <form className='form minisearch' id='search_mini_form' action="" method='get'>
                        <div className='field search'>
                          <button type='button' className='label searchicons'>
                            <span>Chercher</span>
                          </button>
                          <div className='control'>
                            <input id="search" type="text" name="q" value="" placeholder="Recherchez votre produit" class="input-text static_1" maxlength="128" role="combobox" aria-haspopup="false" aria-autocomplete="both" autocomplete="off" />
                            <div id="search_autocomplete" class="search-autocomplete"></div>
                          </div>
                        </div>
                        <div className='actions'>
                          <button type="button" title="Chercher" class="action search jjdddd">
                            <span>Chercher</span>
                          </button>
                        </div>
                      </form>
                    </div>

                  </div>
                </div>

              </div>
              <div className='column4 col-sm-4 col-lg-3 d-flex p-0 side-hed'>
                <div className='col-sm-3 p-0 lang' onClick={this.openSearch1}>
                  <i className='fa fa-globe'></i>
                  <div className='main_dropdown'>
                    <div className='switcher language switcher-language' id='switcher-language'>
                      <strong className='label switcher-label'>
                        <span>Langue</span>
                      </strong>
                      <div className='actions dropdown options switcher-options'>
                        <div class="action toggle switcher-trigger dddd" id="switcher-language-trigger">
                          <strong class="view-main_website_french">
                            <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/smartwave/porto/flags/french.png" />
                            <span>French</span>
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3 p-0 use'>
                  <i className='fa fa-user-o'></i>
                  <ul className='drop-bar'>
                    <div className="drop-button ">
                      <i className="fa fa-chevron-down"></i>
                    </div>
                    <li class="">
                      <span className="span_signup_loginbtn">SignUp/Login</span>
                      <div className="drop-icon">
                        <i className="fa fa-chevron-down"></i></div>
                      <ul className="doper exit">
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/pages/my-account`}>
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/pages/my-account`}>
                            SignUp
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className='col-sm-3 p-0 shopi'>
                  <div className='icon_text'>
                    <div className='minicart-wrapper '>
                      {/* <a className='action showcart' href="#"></a> */}
                      <div className=" main-menu border-section border-top-0">
                        <div className="menu-right pull-right">
                          <div className="icon-nav">
                            <CartContainer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* mbile view */}
          <div className='mobile-header ' style={{ display: "none" }}> 
            <div className='top-mob-sec'>
              <div className='menu-toggle' onClick={this.openNav.bind(this)}>
                <div className='column4 col-sm-4 col-lg-3 d-flex p-0 side-hed'>
                  <div className='mobile-nav'>
                    <button type="button" class="btn btn-success menu_btn_mob" id="menu_btn_mob">
                      <i class="fa fa-bars"></i>
                    </button>
                    <nav id='mob_menu'>
                      {/* ici on retouve le */}
                      <div className='main_menu'>  </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div className='header-search'>
                <label htmlFor="" className='search_toggle_icon_mobile'>
                  <i className='fa fa-search'></i>
                </label>
              </div>
              <div className='header-logo'>
                <div className='column4 col-sm-3 col-lg-3 mobile_logo'>
                  <Link to={`${process.env.PUBLIC_URL}/`} class="logo">
                    <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/logo/default/logo_takaprint.PNG" alt="" width="111" height="50" />
                  </Link>
                </div>
              </div>
              <div className='header-user' onClick={this.userdrop.bind(this)}>
                <div className='col-sm-3 p-0 use'>
                  <i className='fa fa-user-o'></i>
                  <ul class="drop-bar" style={{ display: "block " }}>
                    <div class="drop-button" style={{ display: "none" }}><i class="fa fa-chevron-down"></i></div>
                    <li class="">
                      <span class="span_signup_loginbtn" style={{ display: "none" }}>SignUp/Login</span>
                      <div class="drop-icon">
                        <i class="fa fa-chevron-down" style={{ display: "none" }}></i></div>
                      <ul class="doper " id='exit2'>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/pages/loginhome`}>
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/pages/my-account`}>
                            SignUp
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='header-cart'>
                <div className='col-sm-3 p-0 shopi'>
                  <div className='icon_text'>
                    <div className='minicart-wrapper '>
                      {/* <a className='action showcart' href="#"></a> */}
                      <div className=" main-menu border-section border-top-0">
                        <div className="menu-right pull-right">
                          <div className="icon-nav">
                            <CartContainer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            {/* add side bar */}
            <div id="mySidenav" className="sidenav">
              <a href="javascript:void(0)" className="sidebar-overlay " ></a>
              <nav>
                <a>
                  <div className="sidebar-back text-right">
                    <button className='btn btn-success menu_btn_mob activemobilemenu' id='menu_btn_mob' onClick={this.closeNav}>
                      <i className='fa fa-bars'></i>
                    </button>
                  </div>
                </a>
                <div className="header-links">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-nav-center">
                        <NavBarHome />
                        {/* <MyTabDetailsProduts item={product} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div className="header-links web" id='links12'>
          <div className="row">
            <div className="col-lg-12">
              <div className="main-nav-center" onClick={this.closeLink.bind(this)}>
                <NavBarHome />
              </div>
            </div>
          </div>
        </div>



        {/* sticky menu */}

        <div className='sticky-header none' id='sticky-header' style={{ display: "none" }}>
          <div className='container'>
            <div className='sticky-logo'>
              <Link to={`${process.env.PUBLIC_URL}/`} class="logo">
                <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/logo/default/logo_takaprint.PNG" alt="" width="111" height="50" />
              </Link>
            </div>
            <div className='sticky-menu'>
              <div className='sections nav-sections'>
                <div className="header-links">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="main-nav-center">
                        <NavBarHome />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='sticky-right'>
              <div className='sticky-search'>
                <i className='fa fa-search clickablebtn'></i>
              </div>
              <div className='sticky-user'>
                <div className='col-sm-3 p-0 use'>
                  <i className='fa fa-user-o'></i>
                  <ul class="drop-bar" style={{ display: "block " }}>
                    <div class="drop-button" style={{ display: "none" }}><i class="fa fa-chevron-down"></i></div>
                    <li class="">
                      <span class="span_signup_loginbtn" style={{ display: "none" }}>SignUp/Login</span>
                      <div class="drop-icon">
                        <i class="fa fa-chevron-down" style={{ display: "none" }}></i></div>
                      <ul class="doper " id='exit2'>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/pages/loginhome`}>
                            Login
                          </Link>
                        </li>
                        <li>
                          <Link to={`${process.env.PUBLIC_URL}/pages/my-account`}>
                            SignUp
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='sticky-cart'>
                <div className='col-sm-3 p-0 shopi'>
                  <div className='icon_text'>
                    <div className='minicart-wrapper '>
                      {/* <a className='action showcart' href="#"></a> */}
                      <div className=" main-menu border-section border-top-0">
                        <div className="menu-right pull-right">
                          <div className="icon-nav">
                            <CartContainer />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ici ce trouve le premier header et son sticky header */}

        {/* <header id="sticky" className="sticky "> */}
        {/* {this.state.isLoading ? <Pace color="#27ae60" /> : null}
          <div className="mobile-fix-option"></div> */}
        {/*Top Header Component*/}

        <div className="standar_menu">
          {/* <div className="row justify-content-center align-items-center">
              <div className="col-sm-12 col-lg-11">
                <div className="main-menu border-section border-top-0">
                  <div className="brand-logo layout2-logo">
                    <LogoImage logo={this.props.logoName} />
                  </div>
                  <div>
                    <form className="form_search" role="form">
                      <input id="query search-autocomplete" type="search"
                        placeholder="Recherchez votre produit"
                        className="nav-search nav-search-field" aria-expanded="true" />
                      <button type="submit" name="nav-submit-button" className="btn-search">
                        <i className="fa fa-search"></i>
                      </button>
                    </form>
                  </div>

                  <div className="menu-right pull-right">
                    <div>
                      <div className="icon-nav">
                        <ul className='header-link'>
                          <li className="onhover-div mobile-globe">
                            <div className='first'>

                              <i className="fa fa-globe" aria-hidden="true"></i>
                              <span className='lang'><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/smartwave/porto/flags/french.png" alt="" />
                                <li><a className='lang_item' href='#'>French</a></li></span>

                            </div>
                            <div className="show-div setting">
                              <h6>language</h6>
                              <ul>
                                <li><a href={null} onClick={() => this.changeLanguage('en')}>English</a> </li>
                                <li><a href={null} onClick={() => this.changeLanguage('fn')}>French</a> </li>
                              </ul>
                            </div>
                          </li>

                          <li className=" onhover-div mobile-setting" onClick={this.openSearch1}>
                            <div>
                              <li className="onhover-dropdown mobile-account">
                                <img src={person} className="img-fluid person" alt="" />
                                <ul className=" doper " id='enlever' >

                                  <li>
                                    <Link to={`${process.env.PUBLIC_URL}/pages/loginhome`} data-lng="en">Login</Link>
                                  </li>
                                  <li>
                                    <Link to={`${process.env.PUBLIC_URL}/pages/registerhome`} data-lng="en">Register</Link>
                                  </li>

                                </ul>
                              </li>
                              <span id='doperclose' onClick={this.doperclose2} ></span>

                              <i className="fa fa-search" onClick={this.openSearch}></i> 
                            </div>
                          </li> */}

          {/*Header Cart Component */}
          {/* <CartContainer />
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div> */}
        </div>
        {/* <div className="header-links">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-nav-center">
                  <NavBarHome />
                </div>
              </div>
            </div>
          </div>  */}
        {/* </header> */}

        {/* <div id="search-overlay" className="search-overlay">
          <div>
            <span className="closebtn" onClick={this.closeSearch} title="Close Overlay">×</span>
            <div className="overlay-content">
              <div className="container">
                <div className="row">
                  <div className="col-xl-12">
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Search a Product" />
                      </div>
                      <button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = 1;
  return {
    product: state.data.products.find(el => el.id == productId),
  }
}



export default connect(mapStateToProps, { changeCurrency })(HeaderHome);