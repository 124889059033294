import React, { Component } from 'react';

//common component
import SliderBannerPrint from "./SilderBannerPrint"

//constant
import { STRING_BANNER_PRINT } from '../../../constants/General/index'


export const RenderItemBannerPrint = ({
  title,
  subTitle,
  Image,
  addClass = ''
}) => {
  return (
    <div className="col d-none d-md-block">
      <div className="d-flex justify-content-center align-items-center single-print flex-column">
        <img src={`${Image}`} alt="" style={{ height: "100px" }} />
        <h4>{title}</h4>
        <p>{subTitle} </p>
      </div>
    </div>
  )
}

const BannerPrintHome = () => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="bg-white py-5 px-lg-5">
      <div className='container'>
        <div className="row justify-content-center align-items-center">
          <div className='col-10'>
          <div className='col text-center pb-5'>
            <p style={{ fontSize: "40px", color: "#000" }}>Le print facile en 4 etapes </p>
          </div>

          <div className='col d-flex'>
          {
            STRING_BANNER_PRINT.map((element, index) => (
              <RenderItemBannerPrint
                title={element.title}
                subTitle={element.subtitle}
                Image={element.image}
              />
            ))
          }
          </div>

          {/* slide */}
          <SliderBannerPrint />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerPrintHome;