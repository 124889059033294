import React from 'react';

//constant
import { STRING_SERVICE_CARD } from '../../constants/General'

export const RenderItem = ({ title, bgImage, addClass = '' }) => {
  return (
    <div className={`col-12 col-md-4 col-lg-4 ${addClass}`}>
      <div className='justify-content-center'>
        <div className="position-relative service-card-home">
          <div className={`position-absolute top-0 bottom-0 bg-image ${bgImage}`} />
          <div className="content-text py-2 px-3" >
            <a href="#">{title}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

const ServiceCard = () => {
  return (
    <div className='bg-service-card py-4 px-lg-5'>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className='col d-flex justify-content-center flex-column flex-md-row'>
            {
              STRING_SERVICE_CARD.map((element, index) =>
                <RenderItem
                  key={index}
                  title={element.title}
                  bgImage={element.imageBackground}
                  addClass={index === 1 ? 'my-3 my-md-0' : ''}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard;