import React, { useState } from 'react';

//router
import { Link } from 'react-router-dom';

//redux
import { connect } from "react-redux";

//component
import MyCardComponent from "../common/my-card-component";

const imageRender = (titleLabel) => {
  switch (titleLabel) {
    case 'Meilleures ventes':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/meilleures_ventes_1.png'} height='20px' alt={''} /></span>;
    case 'Carte de visites':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/noel.png'} height='20px' alt={''} /></span>;
    case 'Offres':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/offred.png'} height='20px' alt={''} /></span>;
    case 'Flyers':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/branding_1.png'} height='20px' alt={''} /></span>;
    case 'Carterie':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/carterie.png'} height='20px' alt={''} /></span>;
    case 'Brochures':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/brochure.png'} height='20px' alt={''} /></span>;
    case 'Bloc notes':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/gastronomie.png'} height='20px' alt={''} /></span>;
    case 'Calendrier':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/mariage.png'} height='20px' alt={''} /></span>;
    case 'Materiel de bureau':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/enterrement.png'} height='20px' alt={''} /></span>;
    case 'Affiches':
      return <span className=" d-none d-lg-block inf-arrow"> <img src={'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/imgpsh_fullsize_anim_1_1.png'} height='20px' alt={''} /></span>;
    default:
      break;
  }
}

/** ---------------------------------------------custum component -----------------------------------------start */

// render each title on sidebar
const RenderTabLabel = ({ titleLabel, img, onMouseOver }) => (
  <li className='link-tab'>
    <Link to="#" className={"nav-link-home active-tabs py-lg-2"} onMouseOver={onMouseOver}>
      {imageRender(titleLabel)}
      <span className='d-block icontext'>{titleLabel} </span>
    </Link>
  </li>
)

/** ---------------------------------------------custum component -----------------------------------------end */

const ProductNavBar = ({ item }) => {
  const [toggleState, setToggleState] = useState(0);

  const toggleTab = (index) => {
    setToggleState(index);
  };


  let productByCategory = []

  let category_one = {
    list: []
  }
  let category_two = {
    list: []
  }
  let category_three = {
    list: []
  }
  let category_four = {
    list: []
  }
  let category_five = {
    list: []
  }
  let category_six = {
    list: []
  }
  let category_seven = {
    list: []
  }
  let category_eight = {
    list: []
  }
  let category_night = {
    list: []
  }
  let category_ten = {
    list: []
  }
  let category_eleven = {
    list: []
  }
  item.map(singleProduct => {
    singleProduct.category.map((category) => {
      switch (category) {
        case "Meilleures ventes":
          category_one['category'] = category;
          category_one.list.push(singleProduct);
          productByCategory.push(category_one)
          break;
        case "Offres":
          category_two['category'] = category;
          category_two.list.push(singleProduct);
          productByCategory.push(category_two)
          break;
        case "Carte de visites":
          category_four['category'] = category;
          category_four.list.push(singleProduct);
          productByCategory.push(category_four)
          break;
        case "Flyers":
          category_five['category'] = category;
          category_five.list.push(singleProduct);
          productByCategory.push(category_five)
          break;
        case "Carterie":
          category_six['category'] = category;
          category_six.list.push(singleProduct);
          productByCategory.push(category_six)
          break;
        case "Brochures":
          category_seven['category'] = category;
          category_seven.list.push(singleProduct);
          productByCategory.push(category_seven)
          break;
        case "Bloc notes":
          category_eight['category'] = category;
          category_eight.list.push(singleProduct);
          productByCategory.push(category_eight)
          break;
        case "Calendrier":
          category_night['category'] = category;
          category_night.list.push(singleProduct);
          productByCategory.push(category_night)
          break;
        case "Materiel de bureau":
          category_ten['category'] = category;
          category_ten.list.push(singleProduct);
          productByCategory.push(category_ten)
          break;
        case "Affiches":
          category_eleven['category'] = category;
          category_eleven.list.push(singleProduct);
          productByCategory.push(category_eleven)
          break;

        default:
          break;
      }
    })
    return productByCategory
  })


  return (
    <div className='prodhome'>
      <div className='container main wrapp-content'>
        <div className='row'>
          {/* Tabs Nav */}
          <div className='col-12 col-lg p-0 wrapp-tabs-nav'>
            <ul className='title flex-row flex-lg-column w-100 ui-tabs-nav'>
              {
                //delete the duplicate category and run in new array
                [...new Set(productByCategory)].map((element, index) => (
                  <RenderTabLabel
                    key={index}
                    img={element.image}
                    titleLabel={element.category}
                    onMouseOver={() => toggleTab(index)} />
                ))
              }
            </ul>
          </div>
          {/* Tabs content*/}
          <div className='col col-lg-9 p-0'>
            <div className='content'>
              <div className='container p-0 text-center'>
                {
                  [...new Set(productByCategory)].map((productArray, index) => (
                    <ul key={index} className={toggleState === index ? "nav-submenu-home active-content" : "nav-submenu-home"} >
                      {
                        productArray.list.map((product, index) => (
                          <MyCardComponent
                            id={product.id}
                            key={index}
                            name={product.name}
                            img={product.image}
                            price={product.salePrice}
                            category={''}
                          />
                        ))
                      }
                    </ul>
                  )
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    item: state.data.products,

  }
}
export default connect(mapStateToProps)(ProductNavBar);
