import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import '../../common/index.scss';

// Import custom components
import BannerLogo from "../../common/banner-logo-home";
import SlideComponent from "../../common/slider-home";
import Testimonial from "../../common/testimonial-home";
import ServiceCard from "../../common/service-card-home";
import HeaderHome from "../../common/headers/header-home";
import FooterHome from "../../common/footers/footer-home";
import BannerPrintHome from "../../common/logo-block-home";
import StepPrint from "../../common/Banner-Print/banner-print-home";
import ProductNavBar from "../../common/headers/common/producthome ";

const Home = () => {

  useEffect(() => {
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color15.css`);
  })

    return (
      <div>   
        <Helmet>
          <title>TakaPrint | Home</title>
        </Helmet>
        <HeaderHome logoName={'logo/14.png'} />
        {/* slide section*/}
        <SlideComponent/>
        {/* slide section end*/}

        {/*Step print section */}
        <BannerPrintHome />
        {/*Step print section end*/}

        {/*Product homepage Block section*/}
        <ProductNavBar/>
         {/*Product homepage Block section end*/}
         
        {/* Banner print section*/}
        <StepPrint />
        {/* Banner print section end*/}

        {/* Banner print section*/}
        <Testimonial />
        {/* Banner print section end*/}

        {/* Sercice card */}
        <BannerLogo />

        {/* Sercice card section*/}
        <ServiceCard />
        {/* Sercice card section end */}

        <FooterHome logoName={'layout4/footerlogo.pnHfooter-three'} />
      </div>
    )

}


export default Home;