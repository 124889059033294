import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'


import Breadcrumb from "../common/breadcrumb";
import { getCartTotal } from "../../services";
import { removeFromCart, incrementQty, decrementQty } from '../../actions'
import MyCardComponent from '../products/mySingleProduct/my-card';
// import MyCardResume from '../products/mySingleProduct/my-card'

const RenderElementCardResume = ({ label, value }) => (
    <div className="">
        {/* <span className='label-details'>{label}</span> */}
        <span className='value-details'>{value}</span>

    </div>

)

const MyCardResume = ({
    name = "default name products",
    title = 'default title resume',
    //values
    value_one,
    value_two,
    value_tree,
    value_four,
    value_five,
    value_six,
    value_seven,
    value_eight,
    //label
    label_eight

}) => (
    <div className='content-wrrapper p-3'>
        <div className='resume-title'>
            <h3 className='tiltePanier'>{title}</h3>
        </div>
        {!label_eight &&
            <div className="">
                <span className='tiltePanier'>{name}</span>
            </div>
        }
        <div className="resume-details panier">
            <div className="panierdetail">
                <span className='value-details'>{value_one} </span>
            </div>
            <div className="panierdetail">
                <span className='value-details'>{value_two}</span>
            </div>
            <div className="panierdetail">
                <span className='value-details'>{value_tree} </span>
            </div>
            <div className="panierdetail">
                <span className='value-details'>{value_four} </span>
            </div>
            <div className="panierdetail">
                <span className='value-details'>{value_five} </span>
            </div>
            <div className="panierdetail">
                <span className='value-details'>{value_six} </span>
            </div>
            <div className="panierdetail">
                <span className='value-details'>{value_seven} </span>
            </div>
            {
                label_eight &&
                <div className="">
                    <span className='value-details'>{value_eight} </span>
                </div>
            }
        </div>
    </div>
)


class cartComponent extends Component {

    constructor(props) {
        super(props)


     
    }

    openNav1() {
        var openmyslide = document.getElementById("item2");
        console.log(openmyslide)
        if (openmyslide) {
          console.log('ouvert')
        
        openmyslide.style.display = "none"
        }
      }

    render() {

        const { category, cartItems, symbol, total } = this.props;

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Panier</title>
                    <meta name="description" content="Takaprint" />
                </Helmet>
                {/*SEO Support End */}

                {cartItems.length > 0 ?
                    <div className='maincontent'>
                        <div className='container'>
                            <div className='page messages'>
                                <span>Vous avez ajouté Carte de Visite Standard à votre panier.</span>
                            </div>
                            <div className='columns'>
                                <div className='column main'>
                                    <div className='cart-summary'>
                                        <div className='block shipping'>
                                            <div className='title'>
                                                <strong id='block-shipping-heading'>Estimer la livraison et la TVA</strong>

                                            </div>
                                            <div className='block-summary'>
                                                <table className="table cart-table table-responsive-md">
                                                    <tfoot>
                                                        <tr>
                                                            <td>Sous-total </td>
                                                            <td><h2>{symbol} {total} </h2></td>

                                                            {/* <td>Livraison :</td><br />
                                                        <td><h2> 0 FCFA </h2></td> */}
                                                        </tr>

                                                        <tr>
                                                            <td>Total de la commande</td>
                                                            <td><h2> {symbol} {total} </h2></td>
                                                        </tr>


                                                    </tfoot>
                                                </table>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <Link className='d-block w-100  validemoi text-center' to={`${process.env.PUBLIC_URL}/pages/shipping`}>
                                                         Commander
                                                         </Link>
                                                        </div>
                                            </div>

                                        </div>

                                    </div>
                                    <form action="#" method="post" className='form form-cart' id='form-validate'>
                                        <div className='cart-container'>
                                            <div className='cart table-wrapper'>
                                                <table className='cart items data table'>

                                                    <thead>
                                                        <tr className="table-head tophead">
                                                            <th scope="col item " className='imaget'>Article</th>
                                                            {/* <th scope="col price">Prix</th> */}
                                                            <th scope="col qty" className='quantity'>Quantite</th>
                                                            <th scope="col subtotal" className='soustotal'>sous total</th>

                                                        </tr>
                                                    </thead>

                                                    {cartItems.map((item, index) => {
                                                        return (
                                                            <tbody className='cart item art' key={index} id='item2'>
                                                                <tr className='item-info itembody'>
                                                                    <td className="col-xs-3 col item">
                                                                        <Link to={`${process.env.PUBLIC_URL}/products/${item.id}`} className='product-item-photo'>
                                                                            <span className='product-image-container' style={{ "width": "165px" }}>
                                                                                <span className='product-image-wrapper' style={{ "padding-bottom": "100%" }}>
                                                                                    <img style={{ "max-width": "165px", "max-height": "165px" }} className='product-image-photo' src={item.variants ?
                                                                                        item.variants[0].images
                                                                                        : item.pictures[0]} alt={item.name} />

                                                                                </span>


                                                                            </span>
                                                                        </Link>
                                                                        <div className='product-item-details'>
                                                                            <strong> <Link className='product-item-name' to={`${process.env.PUBLIC_URL}/products/${item.id}`}>{item.name}</Link></strong>
                                                                            <dl className='item-options'>
                                                                                <dt>Description Produit</dt>
                                                                                <dd>
                                                                                    <MyCardResume
                                                                        
                                                                                        value_one={category[0].format}
                                                                                        value_two={category[0].material}
                                                                                        value_tree={category[0].paper}
                                                                                        value_four={category[0].color}
                                                                                        value_five={category[0].page}
                                                                                        value_six={category[0].finition}
                                                                                        value_seven={category[0].book}
                                                                                    />

                                                                                </dd>
                                                                            </dl>

                                                                        </div>

                                                                    </td>
                                                                    {/* <td>
                                                                        <div className="col-xs-3">
                                                                            <h2 className="td-color">{symbol} {item.salePrice - (item.salePrice * item.discount / 100)}</h2>

                                                                        </div>
                                                                    </td> */}
                                                                    <span className='barright'></span>
                                                                    <td className='quantitybox'>
                                                                        <div className="qty-box">
                                                                            <div className="input-group">
                                                                                <span className="input-group-prepend">
                                                                                    <button type="button" className="btn quantity-left-minus" onClick={() => this.props.decrementQty(item.id)} data-type="minus" data-field="">
                                                                                        <i className="fa fa-angle-left"></i>
                                                                                    </button>
                                                                                </span>
                                                                                <input type="text" name="quantity" value={item.qty} readOnly={true} className="form-control input-number" />

                                                                                <span className="input-group-prepend">
                                                                                    <button type="button" className="btn quantity-right-plus" onClick={() => this.props.incrementQty(item, 1)} data-type="plus" disabled={(item.qty >= item.stock) ? true : false}>
                                                                                        <i className="fa fa-angle-right"></i>
                                                                                    </button>
                                                                                </span>
                                                                            </div>
                                                                        </div>{(item.qty >= item.stock) ? 'out of Stock' : ''}
                                                                    </td>
                                                                    <span className='barleft'></span>

                                                                    <td className='sumbox'><h2 className="td-color">{symbol} {item.sum}</h2></td>
                                                                </tr>
                                                                <tr className='item-actions'>
                                                                    <td colSpan={'100px'}>

                                                                        <span className="icon" onClick={() => this.props.removeFromCart(item)}>
                                                                            <i className="fa fa-times"></i>
                                                                        </span>

                                                                    </td>

                                                                </tr>
                                                            </tbody>)
                                                    })}
                                                </table>
                                            </div>

                                        </div>
                                        <div className='cart main actions'>
                                            <Link to={`${process.env.PUBLIC_URL}/`} className=" action continue">Continuez vos achats</Link>
                                            {/* <span className='action'  onClick={this.openNav1.bind(this)}>Vider</span> */}
                                            <span className='action' ><Link to={`${process.env.PUBLIC_URL}/`}>Vider le panier</Link></span>
                                            <span className='action'><Link to={`${process.env.PUBLIC_URL}/checkout`}>Mettre à jour le panier</Link></span>


                                        </div>

                                    </form>

                                    <div className='cart-discount'>
                                        <div className='block discount'>
                                            {/* <div className='title'>
                                            <strong id='block-discount-heading'>Appliquer le code de réduction</strong>
                                        </div> */}
                                            <div className='content'>
                                                <form className='discount-coupon-form' action="">
                                                    <div className='fieldset coupon'>
                                                        <div className='field'>
                                                            <label className='label'>
                                                                <span>Saisir un code de réduction</span>
                                                            </label>
                                                            <div className='control'>
                                                                <input type="text" className='input-text' name="" id="" placeholder="Saisir un code de réduction" />
                                                            </div>
                                                        </div>
                                                        <div className='actions-toolbar'>
                                                            <div className='primary'>
                                                                <button className='action apply primary' type='button' value='Appliquer la réduction'>
                                                                    <span>Appliquer la réduction</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>

                                            </div>
                                        </div>

                                    </div>





                                </div>

                            </div>


                        </div>

                    </div>
                    :
                    <section className="cart-section section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div >
                                        <div className="col-sm-12 empty-cart-cls text-center">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
                                            <h3>
                                                <strong>Your Cart is Empty</strong>
                                            </h3>
                                            <h4>Explore more shortlist some items.</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log('dhjbjdhbjdhbjhd', state.category.resumeTab)
    return {

        cartItems: state.cartList.cart,
        symbol: state.data.symbol,
        total: getCartTotal(state.cartList.cart),
        category: state.category.resumeTab,
    }

}

export default connect(
    mapStateToProps,
    { removeFromCart, incrementQty, decrementQty }
)(cartComponent)

