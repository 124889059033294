import React, { Component } from 'react';

//constants
import {STRING_BANNER_PRINT_HOME} from '../../constants/General/index'

const RenderItem = ({ title, icon }) => {
  return (
    <div className='col-sm-2 p-0 text-center content-elenent-shadow '>
      <img src={`${icon}`} alt="produits de qualit" style={{ height: "45px" }} />
      <p className='pt-2 ' style={{ color: "#000" }} >{title} </p>
    </div>
  )
}



const StepPrint2 = () => {

  return (
    <div className="container Banner-print-home">
      <div className="row justify-content-center align-items-center py-1">
       <div className='row boxcontainer icon-header'>
            {
              STRING_BANNER_PRINT_HOME.map((element, index) =>
                <RenderItem
                  key={index}
                  title={element.title}
                  icon={element.icon}
                />
              )
            }
       </div>
      </div>
    </div>
  )
}

export default StepPrint2;