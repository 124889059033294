// Banner Print home
export const STRING_BANNER_PRINT_HOME = [
  {
    title: 'Produits de qualite',
    icon: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/produits_de_qualit_.png'
  },
  {
    title: "Livraison gratuite",
    icon: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/livraison_gratuite.png'
  },
  {
    title: `100.000+ modèles gratuits`,
    icon:  'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/1000_modeles.png'
  },
  {
    title: 'Éditeur en ligne',
    icon: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/_diteur_en_ligne.png'
  },
  {
    title: 'Paiement sécurisé',
    icon: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/paiement_s_curis_.png'
  }

]

// Banner Print
export const STRING_BANNER_PRINT = [
  {
    title: 'Choisissez votre produit',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/meilleures_ventes_1.png"',
    subtitle: `simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the`,  },
  {
    title: 'Charger et/ou configurer',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage2.png',
    subtitle: `simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the`,  },
  {
    title: 'Paiement par carte ou mobile',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage3.png',
    subtitle: `simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the`,  },
  {
    title: 'Transport rapide',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage4.png',
    subtitle: `simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the`,  }
]


// Testimonial
export const STRING_TITLE_TESTIMONIAL = "Ce que les clients disent de nous"

export const STRING_TESTIMONIAL= [
  {
    title: 'Gsp Technologies',
    legende: 'Gps technologies',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/ck/testimonial/car.png',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque vitae iusto, illo nihil vel facere excepturi aliquam tempora exercitationem placeat provident adipisci voluptates asperiores facilis fuga fugit iste aliquid aut.
    Accusantium dolorem vitae vero praesentium ut amet facere laudantium, dolore autem nisi fugiat repellat distinctio minus iste quam doloremque minima illum dicta asperiores mollitia aperiam accusamus iusto ullam nostrum. Eum.`,
  },
  {
    title: 'Gsp Technologies',
    legende: 'Gps technologies',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/ck/testimonial/mercedes2.png',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque vitae iusto, illo nihil vel facere excepturi aliquam tempora exercitationem placeat provident adipisci voluptates asperiores facilis fuga fugit iste aliquid aut.
    Accusantium dolorem vitae vero praesentium ut amet facere laudantium, dolore autem nisi fugiat repellat distinctio minus iste quam doloremque minima illum dicta asperiores mollitia aperiam accusamus iusto ullam nostrum. Eum.`,
  },
  {
    title: 'Gsp Technologies',
    legende: 'Gps technologies',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/ck/testimonial/suzuki.jpg',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque vitae iusto, illo nihil vel facere excepturi aliquam tempora exercitationem placeat provident adipisci voluptates asperiores facilis fuga fugit iste aliquid aut.
    Accusantium dolorem vitae vero praesentium ut amet facere laudantium, dolore autem nisi fugiat repellat distinctio minus iste quam doloremque minima illum dicta asperiores mollitia aperiam accusamus iusto ullam nostrum. Eum.
    `,
  },
  {
    title: 'Gsp Technologies',
    legende: 'Gps technologies',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/ck/testimonial/suzuki.jpg',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque vitae iusto, illo nihil vel facere excepturi aliquam tempora exercitationem placeat provident adipisci voluptates asperiores facilis fuga fugit iste aliquid aut.
    Accusantium dolorem vitae vero praesentium ut amet facere laudantium, dolore autem nisi fugiat repellat distinctio minus iste quam doloremque minima illum dicta asperiores mollitia aperiam accusamus iusto ullam nostrum. Eum.`,
  },
  {
    title: 'Gsp Technologies',
    legende: 'Gps technologies',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/ck/testimonial/suzuki.jpg',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque vitae iusto, illo nihil vel facere excepturi aliquam tempora exercitationem placeat provident adipisci voluptates asperiores facilis fuga fugit iste aliquid aut.
    Accusantium dolorem vitae vero praesentium ut amet facere laudantium, dolore autem nisi fugiat repellat distinctio minus iste quam doloremque minima illum dicta asperiores mollitia aperiam accusamus iusto ullam nostrum. Eum.`,
  },
  {
    title: 'Gsp Technologies',
    legende: 'Gps technologies',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/ck/testimonial/suzuki.jpg',
    description: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque vitae iusto, illo nihil vel facere excepturi aliquam tempora exercitationem placeat provident adipisci voluptates asperiores facilis fuga fugit iste aliquid aut.
    Accusantium dolorem vitae vero praesentium ut amet facere laudantium, dolore autem nisi fugiat repellat distinctio minus iste quam doloremque minima illum dicta asperiores mollitia aperiam accusamus iusto ullam nostrum. Eum.`,
  }
]


// Service card
export const STRING_SERVICE_CARD = [
  {
    title: 'Impression de qualité',
    imageBackground: 'bg-image-print'
  },
  {
    title: 'Derniers actualités Takaprint',
    imageBackground: 'bg-image-news'
  },
  {
    title: "Nos meilleurs offres",
    imageBackground: 'bg-image-offers'
  }
]

// Banner Product
export const STRING_PRODUCT_ITEM = [
  { 
    title: 'Fashion',
    category: "General",
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/catalog/product/cache/a691ff6deab96036b7e8f89a764f0c13/c/a/carte-de-visite-normales.png',
    price: `100,00 FCFA`,  },
  {
    title: 'Fashion',
    category: "force",
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/catalog/product/cache/a691ff6deab96036b7e8f89a764f0c13/c/a/carte-de-visite-normales.png',
    price: `100,00 FCFA`,  },
  {
    title: 'Fashion',
    category: "brute",
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/catalog/product/cache/a691ff6deab96036b7e8f89a764f0c13/c/a/carte-de-visite-normales.png',
    price: `100,00 FCFA`,  },
  {
    title: 'Fashion',
    category: "action",
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/catalog/product/cache/a691ff6deab96036b7e8f89a764f0c13/c/a/carte-de-visite-normales.png',
    price: `100,00 FCFA`,  }
]

//tab label
export const STRING_TAB_LABEL = [
  {
    title: 'Meilleures ventes',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/meilleures_ventes_1.png',
  },
  {
    title: 'Offres',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/offred.png',
  },
  {
    title: 'Cartes de visite',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/noel.png',
  }, 
  {
    title: 'Flyers',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/branding_1.png',
  },
  {
    title: 'Carterie',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/carterie.png',
  },
  {
    title: 'Brochures',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/brochure.png'
  },
  {
    title: 'Bloc notes',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/gastronomie.png',
  },
  {
    title: 'Calendrier',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/mariage.png',
  },
  {
    title: 'Matériel de bureau',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/enterrement.png',
  },
  {
    title: 'Affiches',
    image: 'http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/imgpsh_fullsize_anim_1.png'
  }
]
