import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import { removeFromWishlist } from '../../actions'
import { getCartTotal } from "../../services";

class Shipping extends Component {

    constructor(props) {
        super(props)

        this.state = {
            payment: 'stripe',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            country: '',
            address: '',
            city: '',
            state: '',
            pincode: '',
            submit: '',
            envoyer: '',
            create_account: ''
        }
        this.validator = new SimpleReactValidator();
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);

    }

    setStateFromCheckbox = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.checked;
        this.setState(obj);

        if (!this.validator.fieldValid(event.target.name)) {
            this.validator.showMessages();
        }
    }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }



    render() {
        const { cartItems, symbol, total } = this.props;

        // Paypal Integration
        const onSuccess = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push({
                pathname: '/order-success',
                state: { payment: payment, items: cartItems, orderTotal: total, symbol: symbol }
            })

        }

        const onCancel = (data) => {
            console.log('The payment was cancelled!', data);
        }

        const onError = (err) => {
            console.log("Error!", err);
        }

        const client = {
            sandbox: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
            production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
        }


        return (
            <div>

                {/*SEO Support*/}
                <Helmet>
                    <title>Shipping</title>
                    <meta name="description" content="Takaprint." />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={'Paiement/Checkout'} />

                <section className="section-b-space adressblo">
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <form>
                                    <div className="checkout row">
                                        <div className="col-lg-6 col-sm-12 col-xs-12 adress">
                                            <div className="checkout-title">
                                                <h6 className='titleadress'>Veuillez indiquer vos coordonnee de livraison</h6>
                                            </div>
                                            <div className="row check-out">
                                                <form action="#" method="post" className='formadress'>
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                        {/* col-md-6 col-sm-6 col-xs-12 */}
                                                        <div className="field-label">Prenom</div>
                                                        <input type="text" name="first_name" value={this.state.first_name} onChange={this.setStateFromInput} />
                                                        {this.validator.message('first_name', this.state.first_name, 'required|alpha')}
                                                    </div>
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Nom famille</div>
                                                        <input type="text" name="last_name" value={this.state.last_name} onChange={this.setStateFromInput} />
                                                        {this.validator.message('last_name', this.state.last_name, 'required|alpha')}
                                                    </div>

                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Email Address</div>
                                                        <input type="text" name="email" value={this.state.email} onChange={this.setStateFromInput} />
                                                        {this.validator.message('email', this.state.email, 'required|email')}
                                                    </div>


                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Address</div>
                                                        <input type="text" name="address" value={this.state.address} onChange={this.setStateFromInput} placeholder="Street address" />
                                                        {this.validator.message('address', this.state.address, 'required|min:20|max:120')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Ville</div>
                                                        <input type="text" name="city" value={this.state.city} onChange={this.setStateFromInput} />
                                                        {this.validator.message('city', this.state.city, 'required|alpha')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                        <div className="field-label">Etat / Region</div>
                                                        <input type="text" name="state" value={this.state.state} onChange={this.setStateFromInput} />
                                                        {this.validator.message('state', this.state.state, 'required|alpha')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                        <div className="field-label">Pays</div>
                                                        <input type="text" name="pincode" value={this.state.pincode} onChange={this.setStateFromInput} />
                                                        {this.validator.message('pincode', this.state.pincode, 'required|integer')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Phone</div>
                                                        <input type="text" name="phone" value={this.state.phone} onChange={this.setStateFromInput} />
                                                        {this.validator.message('phone', this.state.phone, 'required|phone')}
                                                        {/* <select name="country" value={this.state.country} onChange={this.setStateFromInput}>
                                                        <option>Allemagne</option>
                                                        <option>Cameroun</option>
                                                        <option>United State</option>
                                                        <option>Australia</option>
                                                    </select> */}
                                                        {this.validator.message('country', this.state.country, 'required')}
                                                    </div>
                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <Link to={`${process.env.PUBLIC_URL}/pages/Paiementhome`}>
                                                        <input className='d-block w-100  validemoi' type="submit" name="create_account" value='Envoyer' onChange={this.setStateFromInput} />
                                                        {this.validator.message('checkbox', this.state.create_account, 'create_account')}
                                                    </Link>
                                                    </div>

                                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                        <input type="checkbox" name="create_account" id="account-option" checked={this.state.create_account} onChange={this.setStateFromCheckbox} />
                                                        &ensp; <label htmlFor="account-option">Create An Account?</label>
                                                        {this.validator.message('checkbox', this.state.create_account, 'create_account')}
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                        <div className='col-lg-6 col-sm-12 col-xs-12 shipping'>
                                            <div className='cart-summary'>
                                                <div className='block shipping'>
                                                    <div className='title'>
                                                        <strong id='block-shipping-heading'>Recapitulatif de la commande</strong>

                                                    </div>
                                                    <div className='block-summary'>
                                                        <table className="table cart-table table-responsive-md">
                                                            <tfoot>
                                                                <tr>
                                                                    <td>Sous-total </td>
                                                                    <td><h2>{symbol} {total} </h2></td>

                                                                    {/* <td>Livraison :</td><br />
                                                        <td><h2> 0 FCFA </h2></td> */}
                                                                </tr>

                                                                <tr>
                                                                    <td>Total de la commande</td>
                                                                    <td><h2> {symbol} {total} </h2></td>
                                                                </tr>

                                                            </tfoot>
                                                        </table>
                                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <Link to={`${process.env.PUBLIC_URL}/carthome`}>
                                                                <input className='d-block w-100  validemoi' type="submit" name="submit" value='Editer' onChange={this.setStateFromInput} />
                                                            </Link>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                            <div className='cart-summary ship'>
                                                <div className='block shipping'>
                                                    <div className='title'>
                                                        <strong id='block-shipping-heading'>Mode de livraison</strong>

                                                    </div>
                                                    <div className='block-summary'>
                                                        <table className="table cart-table table-responsive-md">
                                                            <tfoot>


                                                                <tr>
                                                                    <td>Frais de livraison inclus</td>
                                                                    <td><h2> Oui </h2></td>
                                                                </tr>

                                                            </tfoot>
                                                        </table>
                                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <Link to={`${process.env.PUBLIC_URL}/carthome`}>
                                                                <input className='d-block w-100  validemoi' type="submit" name="submit" value='Editer' onChange={this.setStateFromInput} />
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    { removeFromWishlist }
)(Shipping)