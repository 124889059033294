import React, { Component } from 'react';
//library
import Slider from 'react-slick';
//style
import '../../common/index.scss';
// redux
import { connect } from "react-redux";

import {
  addToCartUnsafe,
  setPrices,
  setCheckFile,
  addPricNewTab,
} from '../../../actions';
//router
import { Link } from 'react-router-dom';

// import custom Components
import MyBreadcrumb from "../../common/mybreadcrumb";
import ImageZoom from '../common/product/image-zoom';
import SmallImages from '../common/product/small-image';
import MyTabDetailsProduts from './my-tab-details-products';
import MyTabDetailsConfigurationProduts from "./my-details-configuration";

// image
import uploadFile from '../../../assets/images/category/uploadFile.png';
import editFile from '../../../assets/images/category/editFile.png';
import gallery from '../../../assets/images/category/gallery.png';

//services
import { getDeliveryDate, getDeadline, numberWithCommas } from '../../../services';

import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { scroller } from 'react-scroll'

//constants
const iconError = 'http://takaprint-m2-sao076.your-printq.com/pub/static/version1654878054/frontend/Smartwave/porto/fr_FR/Printq_Upload/images/icon_err.png'

// Custum copmonents ------------------------------- start

const MyButton = ({ title = 'default title button', className = 'col-5 py-2 mr-3', onClick }) => (
  <>
    <button
      onClick={onClick}
      className={className}
      type="button">
      {title}
    </button>
  </>
)

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className='d-flex justify-content-between'>
        <h5>{children}</h5>
        {onClose ? (
          <IconButton aria-label="close" className={`${classes.closeButton} p-0`} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </MuiDialogTitle>
  );
});
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const MyButtonUploadFile = ({ title = 'default title button', onClick }) => (
  <>
    <button
      onClick={onClick}
      type="button">
      {title}
    </button>
  </>
)

const MyTitle = ({ title = "default section" }) => (
  < div className="col-12 p-0 pb-3" >
    <h2 className='page-title'>{title} </h2>
  </div >
)

const DeliveryCart = (
  { onClick,
    date = "date",
    price = "price",
    title = "default title delivery section"
  }) => (
  <div className="container p-0 pb-3 pb-md-0 pr-md-2 d-flex justify-content-center align-item-center justify-content-lg-start p-0">
    <div className='col-10 col-lg-12 p-0 wrapper-content' onClick={() => onClick()} >
      <p className='title py-3 m-0'>{title} </p>
      <div className='content-details pt-4'>
        <h3>{price}</h3>
        <p className='m-0 pb-3'>{date} </p>
      </div>
    </div>
  </div>
)

const RenderElementCardResume = ({ label, value }) => (
  <div className="">
    <span className='label-details'>{label}</span>
    <span className='value-details'>{value}</span>
  </div>
)

const MyCardResume = ({
  name = "default name products",
  title = 'default title resume',
  //values
  value_one,
  value_two,
  value_tree,
  value_four,
  value_five,
  value_six,
  value_seven,
  value_eight,
  //label
  label_one,
  label_two,
  label_tree,
  label_four,
  label_five,
  label_six,
  label_seven,
  label_eight

}) => (
  <div className='content-wrrapper p-3'>
    <div className='resume-title'>
      <h3>{title}</h3>
    </div>
    {!label_eight &&
      <div className="">
        <span>{name}</span>
      </div>
    }
    <div className="resume-details">
      <div className="">
        <span className='label-details'>{label_one}</span>
        <span className='value-details'>{value_one} </span>
      </div>
      <div className="">
        <span className='label-details'>{label_two}</span>
        <span className='value-details'>{value_two}</span>
      </div>
      <div className="">
        <span className='label-details'>{label_tree}</span>
        <span className='value-details'>{value_tree} </span>
      </div>
      <div className="">
        <span className='label-details'>{label_four}</span>
        <span className='value-details'>{value_four} </span>
      </div>
      <div className="">
        <span className='label-details'>{label_five}</span>
        <span className='value-details'>{value_five} </span>
      </div>
      <div className="">
        <span className='label-details'>{label_six}</span>
        <span className='value-details'>{value_six} </span>
      </div>
      <div className="">
        <span className='label-details'>{label_seven}</span>
        <span className='value-details'>{value_seven} </span>
      </div>
      {
        label_eight &&
        <div className="">
          <span className='label-details'>{label_eight}</span>
          <span className='value-details'>{value_eight} </span>
        </div>
      }
    </div>
  </div>
)

const SectionLayout = (
  { id = null,
    children,
    classStyle = 'col-12 col-lg-8',
    className = null,
    title = 'default title',
  }) => (
  <section id={id} className={`${className} pt-0`} >
    <div className="container ">
      <div className="row py-5">
        <div className='col-12 px-lg-5'>
          <div className="col-12  col-12 px-lg-5 p-0">
            <div className={`${classStyle} px-0`}>
              {/* title */}
              {title !== '' ? <MyTitle title={title} /> : null}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)


class Columnhome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      newQuantity: 0,
      file: {},
      activeClass: false,
      option_price: [],
      openDialog: false,
      showDetailsUpload: false,
      showDetailsTemplateGallery: false,
      showDetailsOnlineEditeur: false,
      file_upload: {
        name: '',
        size: 0,
        pages: 0,
      },
      showErrorAddQty: false,
      showErrorAddQtyExit: false,
      checFile1: false,
      checFile2: false,
      // section check file
      checkFileNo: false,
      checkFileYes: false,
      closeFile: false
    };
    // this._checkFile = this._checkFile.bind(this);
    this._deleteFile = this._deleteFile.bind(this);
    this._previewFile = this._previewFile.bind(this);
    this._downloadFIle = this._downloadFIle.bind(this);
    this._rearrangeFile = this._rearrangeFile.bind(this);
    this._changeHandler = this._changeHandler.bind(this);
    this._numberOfPages = this._numberOfPages.bind(this);
    // this._activateCheck = this._activateCheck.bind(this);
    // this._price_selected = this._price_selected.bind(this);

  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });

  }

  /*************************************
   *  start function code
   */

  //this function allows you to manage the logic of the file verification section in order to update the style
  _activateCheck = (btnYes = false, btnNo = false) => {
    if (btnNo) {
      this.setState(prevState => ({
        ...prevState,
        closeFile: false
      }))
      this._checkFile(0, false)
    } else if (btnYes) {
      this.setState(prevState => ({
        ...prevState,
        closeFile: true
      }))
    }
    this.setState(preveState => ({
      ...preveState,
      checkFileNo: btnNo,
      checkFileYes: btnYes,
    }))
    if (btnNo == true) { //scroll si on non est selectionné
      scroller.scrollTo('upload-file', {
        duration: 1500,
        delay: 100,
        smooth: true,
      })
    }
  }
  _closeSectionCheckFIle = () => {
    this.setState(preveState => ({
      ...preveState,
      closeFile: false
    }))
    scroller.scrollTo('upload-file', {
      duration: 1500,
      delay: 100,
      smooth: true,
    })
  }
  //this function updates the proof value in resume side bar
  _checkFile = (delivery, one = false, two = false) => {
    this.setState(prevState => ({
      ...prevState,
      checFile1: one,
      checFile2: two
    }))
    this.props.dispatch(setCheckFile(delivery))
    scroller.scrollTo('upload-file', {
      duration: 1500,
      delay: 100,
      smooth: true,
    })
  }

  //add the new quantity
  _addNewQuantity = (productId, tableDate) => {
    let convertInNumber = parseInt(this.state.newQuantity, 10);
    if (!isNaN(convertInNumber)) {
      if (convertInNumber >= 100 && convertInNumber <= 5000) {
        let newquantity = {
          quantity: convertInNumber,
          possibility: []
        }
        if (convertInNumber < 1500) {
          for (let index = 0; index < tableDate.length; index++) {
            newquantity.possibility.push({
              totalPrice: convertInNumber,
              single: convertInNumber,
            })
          }
          this.props.dispatch(addPricNewTab(newquantity, productId))
          this.setState(preveState => ({
            ...preveState,
            openDialog: false
          }))

        } else {
          for (let index = 0; index < tableDate.length; index++) {
            newquantity.possibility.push(null)
          }
          newquantity.possibility.fill({
            totalPrice: convertInNumber,
            single: convertInNumber,
          }, tableDate.length - 1)
          this.setState(preveState => ({
            ...preveState,
            openDialog: false
          }))
          this.props.dispatch(addPricNewTab(newquantity, productId))

        }
      } else {
        this.setState(prevState => ({
          ...prevState,
          showErrorAddQty: true
        }))
        toast.error("Vous devez entrez un nombre compris entre 100 et 5000");
      }
    } else {
      toast.error("'veillez entrer un nombre entier");
    }
  }

  //foramtbyte
  _formatByte(byte) {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let l = 0, n = parseInt(byte, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  //get number of page
  _numberOfPages(file) {
    const reader = new FileReader();
    let count = 0;
    reader.readAsBinaryString(file);
    reader.onloadend = function () {
      try {
        count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
      } catch (error) {
        count = null
      }
      return count;
    }
  }

  //file  traitment
  _changeHandler(event) {
    let file = event.target.files[0];
    console.log('skjbkskgbkdgjhbdksjbvkjdhbvkjdbvkjas;nfpaw', typeof file)

    if (file.type == 'image/*') {
      this.setState(prevState => ({
        file_upload: {                   // object that we want to update
          ...prevState.file_upload,    // keep all other key-value pairs
          name: file.name,       // update the value of specific key
          size: this._formatByte(file.size)
        }
      }))
      this.setState(prevState => ({
        ...prevState,
        showDetailsUpload: true
      }))
      return
    } else {
      //done
      const objectUrl = URL.createObjectURL(file)
      this.setState(prevState => ({
        file_upload: {                   // object that we want to update
          ...prevState.file_upload,    // keep all other key-value pairs
          name: file.name,       // update the value of specific key
          size: this._formatByte(file.size),
          pages: this._numberOfPages(file),
        }
      }))

      this.setState(prevState => ({
        ...prevState,
        showDetailsUpload: true
      }))
    }


  }

  _price_selected = (elem, tableDate) => {
    let price = {
      totalPrice: elem.totalPrice,
      day: `${tableDate.week}  ${tableDate.day}`
    }
    //change style
    // this.setState(prevState => ({
    //   ...prevState,
    //   activeClass: !prevState.activeClass
    // }))

    this.props.dispatch(setPrices(price))
    scroller.scrollTo('check-file', {
      duration: 1500,
      delay: 100,
      smooth: true,
    })
  }

  _deleteFile() {
    this.setState(prevState => ({
      ...prevState,
      showDetailsUpload: false
    }))
  }
  _previewFile() {
    console.log('Preview')
  }
  _downloadFIle() {
    console.log('Download')
  }
  _rearrangeFile() {
    console.log('Rearrange')
  }

  // fenetre modale
  doperopen() {
    document.getElementById("custom-quantity").classList.add('.afficher');
    console.log('ouverture')

  }
  doperclose() {
    console.log('Fermeture')
    // document.querySelector("#custom-quantity").classList.add('modal');  
  }
  // eslint-disable-next-line no-restricted-globals
  _handleClickOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      openDialog: true
    }))

  };
  _handleClose = () => {
    this.setState(prevState => ({
      ...prevState,
      openDialog: false
    }))
  };
  /*
   *  End function code
   **************************************/


  render() {
    let activeBtn = this.state.active ? 'active-btn' : '';
    const { product, category, dispatch, price, prices, checkFile, ownProps } = this.props
    let products = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      fade: true
    };
    let productsnav = {
      slidesToShow: 3,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      focusOnSelect: true
    };

    return (
      <div className='my-single-product-section'>

        <MyBreadcrumb title={product.name} parent={product.category} />

        {/*Section Details Start*/}
        {(product) ?
          <>

            {/* eslint-disable-next-line no-restricted-globals  */}
            <Dialog onClose={() => this._handleClose()} className='customized-dialog-title' aria-labelledby="customized-dialog-title" open={this.state.openDialog}>
              <DialogTitle id="customized-dialog-title" onClose={() => this._handleClose()}>
                Ajouter votre Quantité
              </DialogTitle>
              <DialogContent dividers>
                <div className="info-dialog d-flex ">
                  <span><i className="fa fa-info-circle" aria-hidden="true"></i></span>
                  <p className='ml-2 mb-0'>Vous devez entrez un nombre compris entre 100 et 5000</p>
                </div>
                <div className="form-group">
                  <label>Quantity</label>
                  <input type="tel" className="form-control" name="custom_qty" placeholder="e.g. 300" data-min-qty="100" data-max-qty="50000" onChange={(event) => this.setState({ newQuantity: event.target.value })} />
                  <ul className={`${this.state.showErrorAddQty ? 'messages_warning' : 'd-none'}`}>
                    <li>
                      <ul>
                        <li className={`${this.state.showErrorAddQty ? 'error_qty_amount' : 'd-none'}`}>
                          <span>* Vous devez entrez un nombre compris entre 100 et 5000 pieces</span>
                        </li>
                        <li className={`${this.state.showErrorAddQtyExit ? 'error_qty_exist' : 'd-none'}`}>
                          <span>*Ce produit existe déjà</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </DialogContent>
              <DialogActions>
                <button type="button" className="add_qty btn" onClick={() => this._addNewQuantity(product.id, product.purchase_days)}>Ajouter</button>
                <button type="button" className="cancel_add_qty btn" onClick={() => this._handleClose()}>Annuler</button>
              </DialogActions>
            </Dialog>
            {/* details section */}
            <section className='container-collection pt-0'>
              <div className="collection-wrapper">
                <div className="descp">
                  <div className='my-product-description'>
                    <div className='container'>
                      <div className="row py-4">
                        <div className='col-12 p-0 px-lg-5'>
                          <div className="col-12 p-0 px-lg-5">
                            {/* title */}
                            <MyTitle title={product.name} />
                            {/*section description start  */}
                            <div className="col-12 p-0 flex-none d-lg-flex ">
                              {/* setion image */}
                              <div className="col-12 col-lg-4 px-0 pr-lg-2">
                                <div className='product-thumbnail p-3'>
                                  <Slider {...products} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-right-slick">
                                    {product.variants.map((vari, index) =>
                                      <div key={index}>
                                        <ImageZoom image={vari.images} className="img-fluid image_zoom_cls-0" />
                                      </div>
                                    )}
                                  </Slider>
                                  <SmallImages item={product} settings={productsnav} navOne={this.state.nav1} />
                                </div>
                              </div>
                              {/*section tabs start  */}
                              <div className="col-12 col-lg-7 tab-product pt-4 px-0 p-lg-0 m-0">
                                <MyTabDetailsProduts item={product} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>

            {/* config section */}
            <SectionLayout
              classStyle='col-12'
              title='Configuration'
              id="configuration-sections"
              className='configuration-section-wrapper'
            >
              <>
                <div className="col-12 p-0">
                  <h3 className='subtitle-config'> {product.name} </h3>
                </div>
                <div className="col-12 col-lg-12 tab-product px-0 p-lg-0 m-0">
                  <MyTabDetailsConfigurationProduts product={product} ownProps={ownProps} />
                </div>
              </>
            </SectionLayout>

            {/* price section and recap */}
            <div className='col-12 p-0 position-relative'>
              {/* price section and recap */}

              <SectionLayout
                id='price-recap'
                title='Prix et recapitulatif'
                className='price-recap-section-wrapper'
              >
                <div className='col-12 price-render price_list pricing preload_superfast exist bg-white'>
                  <div className='col-12 row pricing-header bg-white m-0'>
                    <div className='col-4 col-lg-2 x-axis p-0 d-flex flex-column align-items-end pr-2'>
                      <h3 className='text-right'>Quantities</h3>
                      <span id="custom-quantity-popup" className="custom-quantity-popup mt-2" onClick={() => this._handleClickOpen()}>Add Custom</span>
                    </div>

                    {/* ********************day************************************ */}
                    <div className='col y-axis p-0'>
                      <ul className='pricing-header-ul'>
                        {
                          product.purchase_days.map((elem, index) => (
                            <li>
                              <h4>Get now </h4>
                              <p className="date_text_recieve" >
                                <span style={{ "color": "#00ad28;" }}>
                                  <span className="weekday" style={{ "color": "#00ad28;" }}>{elem.week} </span>
                                  <span className="calendar" style={{ "color": "#00ad28;" }}>{elem.day} </span>
                                </span>
                              </p>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>

                  {/* ********************price************************************ */}
                  {
                    product.priceTest2.sort((a, b) => a.quantity - b.quantity).map((elem, index) => (
                      <div className='pricing-content row m-0'>
                        <div className='col-4 col-lg-2 qty row'>
                          <div className="best-seller-wrapper">
                            <span>{elem.quantity} pcs</span>
                            <span>Best Seller</span>
                          </div>
                        </div>
                        <div className='col p-0' style={{ "padding": "0;" }}>
                          <ul>
                            {
                              elem.possibility.map((elem, index) => (
                                elem ? <li key={index}>
                                  <div className="block_product">
                                    {

                                      <a role="button" key={index} className={`${this.state.activeClass ? 'product-active' : null}`} onClick={() => this._price_selected(elem, product.purchase_days[index])}>
                                        <span>  {elem !== {} ? 'FCFA' + elem.totalPrice : ''} </span>
                                        <small>FCFA{elem.single} per pieces </small>
                                      </a>
                                    }
                                  </div>
                                </li> :
                                  <li key={index}>
                                    <div className="block_product">
                                    </div>
                                  </li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    ))
                  }
                  <div className="my-3 py-3 " onClick={() => this._handleClickOpen()}>
                    <p className="load-price-more hide"><i className="fa fa-arrow-circle-down" aria-hidden="true"></i></p>
                    <p className="custom-quantity-popup"><i className="fa fa-plus" aria-hidden="true"></i>&nbsp;Add Custom Quantity</p>
                  </div>
                </div>

              </SectionLayout>

              {/* check file section */}
              <SectionLayout
                id='check-file'
                title='Verificaton de fichier'
                className='check-file-wrapper'
              >

                <div className='col-12 col-lg-6 p-0'>
                  {/* add new bloc */}
                  <button
                    onClick={() => this._checkFile({ option: "Standard", amount: 0 }, true, false)}
                    className={`col-5 py-3 p-0  mr-3 ${this.state.checFile1 ? 'active-btns' : ''}`}
                    type="button">
                    <span className="d-block py-2">Standard proof </span>
                    <p className="d-block p-0">Gratuit </p>
                  </button>
                  <button
                    onClick={() => this._checkFile({ option: "Advanced", amount: 10 }, false, true)}
                    className={`col-5 py-3 p-0  mr-3 ${this.state.checFile2 ? 'active-btns' : ''}`}
                    type="button">
                    <span className="d-block py-2">Advanced proof </span>
                    <p className="d-block p-0"> +$10  </p>
                  </button>
                  {/* 
                    I DO THE MAP HERE!!!!! don't delete please.
                    <MyButton
                  title='Oui'
                  className={activeBtn}
                  onClick={() => this._activateCheck(true)}
                  />
                  <MyButton
                    title='Non'
                    className={activeBtn}
                    onClick={() => this._activateCheck(false)}
                  /> */}
                  <div className="container p-0">
                    {/* <button
                      onClick={() => this._activateCheck(true, false)}
                      className={`col-5 py-2 mr-3 ${this.state.checkFileYes ? 'active-btns' : ''}`}
                      type="button">
                      oui
                    </button>
                    <button onClick={() => this._activateCheck(false, true)}
                      className={`col-5 py-2 mr-3 ${this.state.checkFileNo ? 'active-btns' : ''}`}
                      type="button">
                      non
                    </button> */}

                    {
                      this.state.closeFile &&
                      <div className="col-12 pt-4">
                        <div className='col-12 text-sm-center text-md-left title-check-bloc'><span className="d-block">Verification</span></div>
                        <div className='col-12 p-0'>
                          {/* {
                            product.checkFile.map((elem, index) => (
                              <button
                                key={index}
                                onClick={() => this._checkFile(elem, true)}
                                className={`col-5 py-3 p-0  mr-3 ${this.state.checFileValue? 'active-btns':''}`}
                                type="button">
                                <span className="d-block py-2">{elem.option} proof </span>
                                <p className="d-block p-0">{elem.amount === 0 ? 'Gratuit' : `+ $ ${elem.amount}`} </p>
                              </button>
                            ))
                          } */}
                          <button
                            onClick={() => this._checkFile({ option: "Standard", amount: 0 }, true, false)}
                            className={`col-5 py-3 p-0  mr-3 ${this.state.checFile1 ? 'active-btns' : ''}`}
                            type="button">
                            <span className="d-block py-2">Standard proof </span>
                            <p className="d-block p-0">Gratuit </p>
                          </button>
                          <button
                            onClick={() => this._checkFile({ option: "Advanced", amount: 10 }, false, true)}
                            className={`col-5 py-3 p-0  mr-3 ${this.state.checFile2 ? 'active-btns' : ''}`}
                            type="button">
                            <span className="d-block py-2">Advanced proof </span>
                            <p className="d-block p-0"> +$10  </p>
                          </button>
                        </div>
                        <div onClick={() => this._closeSectionCheckFIle()} className="col-12 close-btn text-right">
                          <span>X</span>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </SectionLayout>

              {/* delivery section */}
              {/* <SectionLayout
                id='delivery-option'
                title='Option de livraison'
                className='delivery-wrapper'
              >
                <div className="col-12 d-flex flex-column flex-md-row justify-content-center align-item-center justify-content-lg-start p-0">

                  {
                    product.delivery.map((option, index) => (
                      <DeliveryCart
                        onClick={() => dispatch(addDeliveryOption(option))}
                        key={index}
                        date={option.date}
                        title={option.title}
                        price={option.price == 0 ? 'Gratuit' : option.price + 'FCFA'}
                      />
                    ))
                  }
                </div>


              </SectionLayout> */}

              {/* section upload file */}
              <SectionLayout
                id='upload-file'
                title='Changer ou créez votre design'
                className='upload-file-wrapper'
              >
                <div className=' p-0 d-lg-flex col-12'>
                  {/* 1 */}
                  {
                    product.downloadFile.upload
                      ?
                      <div className='col col-lg-4 p-0'>
                        <div className='bg-white'>
                          <div className='col col-lg-12 p-0 content-input-file position-relative' style={{ backgroundImage: `url(${uploadFile})` }}>
                            <div className='col-12 p-0 h-100'>
                              <input type="file" name="file" onChange={(event) => this._changeHandler(event)} accept=".jpg, .jpeg, .png, .pdf, .tif, .txt" />
                              {/* <input type="file" name="name" onChange={(event) => console.log('dskjnvkjsnlvkajnlaksjvnlakjsnvsavknsj', event.target.files)}/> */}
                            </div>
                            <span>{product.name} </span>
                          </div>

                          {/* box view details upload */
                            this.state.showDetailsUpload ?
                              <>
                                <div className='text-right close-view-upload-details'
                                  onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    showDetailsUpload: false
                                  }))}> <span>x</span>
                                </div>

                                <div className='wrapper-file-uploaded-box-details'>
                                  <div className='col-12 file-uploaded-box p-0 d-flex justify-content-end align-items-center flex-column'>
                                    <h3>{product.name}({this.state.file_upload.name}) {this.state.file_upload.size} {this.state.file_upload.pages}</h3>
                                    <span>Telechargement termine</span>
                                  </div>

                                  <div className='col-12 p-0 pt-3'>
                                    {/*
                              <div className="col-12 p-0 pb-3 uploaded-file-error-box">
                                <div className="col-12 p-0 format-error d-flex justify-content-between">
                                  <div className="format"> <p>Format</p> </div>
                                  <div className="col-9 p-0 error-message">
                                    <div className='d-flex justify-content-between'>
                                      <h3>{category[0].format} </h3>
                                      <img src={iconError} alt="error icon" />
                                    </div>
                                    <p>The format of the uploaded file does not meet the selected one. Uploaded format: 21.59 x 27.94 cm</p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-12 p-0 pb-3 uploaded-file-error-box">
                                <div className="col-12 p-0 format-error d-flex justify-content-between">
                                  <div className="format"> <p>Page</p> </div>
                                  <div className="col-9 p-0 error-message">
                                    <div className='d-flex justify-content-between'>
                                      <h3>{category[0].page} </h3>
                                      <img src={iconError} alt="error icon" />
                                    </div>
                                    <p>The number of pages of the uploaded file does not meet the selected number. Number of uploaded pages: {this.state.file_upload.pages} page(s)</p>
                                  </div>
                                </div>
                              </div> */}

                                    <div className='col-12 p-0'>
                                      {/* progress bar */}
                                      <div className='col-12 p-0 wrapper-progress-bar-result text-center'>
                                        <span>100%</span>
                                      </div>
                                      <div className="col-12 p-0 wrapper-button">
                                        <MyButtonUploadFile title='Supprimer' onClick={this._deleteFile} />
                                        <MyButtonUploadFile title='Prévisualiser' onClick={() => this._previewFile()} />
                                        <MyButtonUploadFile title='Télecharger' onClick={() => this._downloadFIle()} />
                                        <MyButtonUploadFile title='Rearranger' onClick={() => this._rearrangeFile()} />
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </>
                              : null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                  {/* 2 */}
                  {
                    product.downloadFile.gallery
                      ?
                      <div className='col col-lg-4 p-0 mt-2 mt-lg-0 ml-lg-2'>
                        <div className='bg-white'>
                          <div className='col col-lg-12 p-0 content-input-file position-relative' style={{ backgroundImage: `url(${gallery})` }}>
                            <div className='col-12 p-0 h-100'>
                              <input type="file" name="file" onChange={(event) => this._changeHandler(event)} accept=".jpg, .jpeg, .png, .pdf, .tif, .txt" />
                              {/* <input type="file" name="name" onChange={(event) => console.log('dskjnvkjsnlvkajnlaksjvnlakjsnvsavknsj', event.target.files)}/> */}
                            </div>
                            <span>{product.name} </span>
                          </div>

                          {/* box view details upload */
                            this.state.showDetailsTemplateGallery ?
                              <>
                                <div className='text-right close-view-upload-details'
                                  onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    showDetailsTemplateGallery: false
                                  }))}> <span>x</span>
                                </div>

                                <div className='wrapper-file-uploaded-box-details'>
                                  <div className='col-12 file-uploaded-box p-0 d-flex justify-content-end align-items-center flex-column'>
                                    <h3>{product.name}({this.state.file_upload.name}) {this.state.file_upload.size} {this.state.file_upload.pages}</h3>
                                    <span>Telechargement termine temolate gallery</span>
                                  </div>

                                  <div className='col-12 p-0 pt-3'>
                                    <div className='col-12 p-0'>
                                      {/* progress bar */}
                                      <div className='col-12 p-0 wrapper-progress-bar-result text-center'>
                                        <span>100%</span>
                                      </div>
                                      <div className="col-12 p-0 wrapper-button">
                                        <MyButtonUploadFile title='Supprimer' onClick={this._deleteFile} />
                                        <MyButtonUploadFile title='Prévisualiser' onClick={() => this._previewFile()} />
                                        <MyButtonUploadFile title='Télecharger' onClick={() => this._downloadFIle()} />
                                        <MyButtonUploadFile title='Rearranger' onClick={() => this._rearrangeFile()} />
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </>
                              : null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                  {/* 3 */}
                  {
                    product.downloadFile.edit
                      ?
                      <div className='col col-lg-4 p-0  mt-2 mt-lg-0 ml-lg-2'>
                        <div className='bg-white'>
                          <div className='col col-lg-12 p-0 content-input-file position-relative' style={{ backgroundImage: `url(${editFile})` }}>
                            <div className='col-12 p-0 h-100'>
                              <input type="file" name="file" onChange={(event) => this._changeHandler(event)} accept=".jpg, .jpeg, .png, .pdf, .tif, .txt" />
                              {/* <input type="file" name="name" onChange={(event) => console.log('dskjnvkjsnlvkajnlaksjvnlakjsnvsavknsj', event.target.files)}/> */}
                            </div>
                            <span>{product.name} </span>
                          </div>

                          {/* box view details upload */
                            this.state.showDetailsTemplateGallery ?
                              <>
                                <div className='text-right close-view-upload-details'
                                  onClick={() => this.setState(prevState => ({
                                    ...prevState,
                                    showDetailsTemplateGallery: false
                                  }))}> <span>x</span>
                                </div>

                                <div className='wrapper-file-uploaded-box-details'>
                                  <div className='col-12 file-uploaded-box p-0 d-flex justify-content-end align-items-center flex-column'>
                                    <h3>{product.name}({this.state.file_upload.name}) {this.state.file_upload.size} {this.state.file_upload.pages}</h3>
                                    <span>Telechargement termine Editeur en ligne</span>
                                  </div>

                                  <div className='col-12 p-0 pt-3'>
                                    <div className='col-12 p-0'>
                                      {/* progress bar */}
                                      <div className='col-12 p-0 wrapper-progress-bar-result text-center'>
                                        <span>100%</span>
                                      </div>
                                      <div className="col-12 p-0 wrapper-button">
                                        <MyButtonUploadFile title='Supprimer' onClick={this._deleteFile} />
                                        <MyButtonUploadFile title='Prévisualiser' onClick={() => this._previewFile()} />
                                        <MyButtonUploadFile title='Télecharger' onClick={() => this._downloadFIle()} />
                                        <MyButtonUploadFile title='Rearranger' onClick={() => this._rearrangeFile()} />
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </>
                              : null
                          }
                        </div>
                      </div>
                      :
                      null
                  }
                </div>
              </SectionLayout>
              <SectionLayout
                id=''
                title=''
                className='wrapperAddCard'>
                <div>
                  <span onClick={() => dispatch(addToCartUnsafe(product, 1))}>Ajouter au panier</span>
                </div>
              </SectionLayout>
              {/* resume */}
              <div className="col-lg-3 pb-4 pb-lg-0 resume-wrapper">
                <div className='col-lg-12 p-0'>
                  {/* title */}
                  <MyCardResume
                    title={'Resume'}
                    name={product.name}
                    label_one={'Format'}
                    label_two={'Materials'}
                    label_tree={'Type de papier'}
                    label_four={'coloris'}
                    label_five={'pages'}
                    label_six={'Finitions'}
                    label_seven={'Book Boding'}
                    // value
                    value_one={category[0].format}
                    value_two={category[0].material}
                    value_tree={category[0].paper}
                    value_four={category[0].color}
                    value_five={category[0].page}
                    value_six={category[0].finition}
                    value_seven={category[0].book}
                  />
                  <MyCardResume
                    title={'Informations'}
                    label_two='Gross Price'
                    label_one='Delivery date'
                    label_tree='Deadline'
                    label_four='Quantité'
                    label_five='Proof'
                    label_six='Net price'
                    label_seven='Tax 0%'
                    label_eight={'Poids total'}
                    // value
                    value_two={price.amount}
                    value_seven={product.tax}
                    value_tree={getDeadline()}
                    value_four={price.quantity}
                    value_eight={product.weight}
                    value_one={prices.day}
                    //value_one={getDeliveryDate()}
                    value_six={prices.totalPrice}
                    //value_six={checkFile.amount !== undefined ? price.amount + checkFile.amount + priceDelivery.price : price.amount}
                    value_five={checkFile.option !== undefined ? `${checkFile.option}: +$ ${checkFile.amount}` : '00$'}
                  />
                  <div className="col p-0">
                    <button className='w-100 p-2' onClick={() => dispatch(addToCartUnsafe(product, 1))}>
                      <Link className="text-white" to={`${process.env.PUBLIC_URL}/carthome`}> Ajouter au panier</Link>
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </>
          : <p>Pas de produits disponibles</p>
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let productId = ownProps.match.params.id;
  return {
    productId,
    ownProps,
    prices: state.category.prices,
    price: state.category.price, // price of old tab quantity
    productPrice: state.data.products, // reload state
    category: state.category.resumeTab,
    checkFile: state.category.checkFile,
    priceDelivery: state.category.deliveryOption,
    product: state.data.products.find(el => el.id == productId),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Columnhome);