import { List } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
// import App from '../app';
// import Accordion from './Accordion';
import App from './collapselogique';
import Breadcrumb from '../common/breadcrumb';
import MyBreadcrumb from '../common/mybreadcrumb';

const Blog = () => {

  const EpandAll = () => {
    const open = document.querySelector(".accordion")
    console.log(open)
    const item = open.querySelector('.accordion-content');
    console.log('item', item)
    item.classList.add('Expan')
    // item ?  isActive ? '-' : '+'
  };

  const [isActive, setIsActive] = useState(false);

 

  const accordionData = [
    {
      title: 'Section 1',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    },
    {
      title: 'Section 2',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`
    },
    {
      title: 'Section 3',
      content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`
    }
  ];


  return (
    <div>
    {/* <Breadcrumb  title={'Blog'}/> */}
      <div class="faqtopbanner">
        <div class="faq_mainbox">
          <h1>BLOG</h1>
          <div class="faq_searchbox block-search">
            <input type="text" name="faq_search" placeholder="Rechercher des articles ici..." />
            <div class="actions">
              <button class="btn button action search"></button>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion">
        <div class="row faq-main-box">
   Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquid atque, illum praesentium accusantium minus enim soluta tempore. Consequuntur porro repellat vitae? Vel eos quis cum placeat sit eius assumenda suscipit.
   Voluptatibus qui dicta, eum, id nam soluta nostrum quis eius iure sint nulla eveniet, eligendi atque aspernatur. Adipisci eveniet soluta, provident sequi dolores, aperiam repellat qui obcaecati at impedit facere?
   Sed, magnam totam perspiciatis voluptatibus ducimus temporibus, assumenda nulla nobis rem incidunt natus corrupti suscipit culpa. Distinctio quidem, voluptatum expedita adipisci consequuntur tempora placeat modi, nostrum quisquam provident ullam nesciunt.
   Maxime voluptatum, quod fugiat corrupti ad consequuntur, quis nulla harum cum beatae in. Autem rerum quod officiis atque esse, omnis deleniti accusamus animi, reiciendis quia ipsa itaque optio consequatur perferendis?
   Velit repudiandae magnam quod inventore eveniet aperiam temporibus, molestiae architecto, ad adipisci illum deserunt voluptatibus dolor, odit repellat harum quidem cupiditate. Earum illo dolore dolor laudantium. Magni rerum dolorum deserunt!
   Sit aspernatur illo a, libero accusamus dolore facilis atque est maiores dolorum cumque animi ut architecto, eaque inventore quaerat omnis rem eligendi consequuntur natus magnam. Consectetur necessitatibus quaerat natus corporis!
   Rerum architecto mollitia error facere perferendis eaque. In voluptates aliquid numquam delectus esse obcaecati dignissimos quasi, omnis ipsum hic iste debitis modi blanditiis accusantium quos cupiditate odit aliquam, sequi impedit?
   Corporis reprehenderit accusamus, dolore provident facere odio distinctio voluptate quia totam officia non velit. Nam repellat nostrum, quia necessitatibus provident quasi distinctio qui temporibus iste ipsum quae excepturi, totam fugit?
   Pariatur eaque excepturi consequatur odio maxime ipsa neque ab labore ex provident delectus, iste velit quisquam laborum debitis. Molestias placeat atque dolore earum dolorum natus, quia sint expedita error adipisci.
   Similique illo, dolore mollitia, quae optio ipsum repudiandae vitae sed iure veritatis laudantium quod veniam distinctio architecto ratione ut labore explicabo doloribus? Eos facere repudiandae, culpa non mollitia tempora doloribus.
   Soluta iusto et adipisci iure accusantium id ex sapiente aut quaerat voluptates ullam sequi, voluptate voluptatibus saepe accusamus earum doloremque quo ratione cum, suscipit est illum incidunt. Tempore, velit minima.
   Mollitia atque exercitationem temporibus eligendi earum nesciunt placeat, eius consequatur repellat eveniet animi deserunt repellendus, sunt assumenda, nostrum modi? Nihil ipsum quidem sunt sed iure perferendis numquam nisi suscipit nulla!
   Ipsum, accusantium velit officia soluta tempora ut cumque nulla sunt minus totam inventore aperiam odit corrupti nobis excepturi beatae odio eos animi ullam asperiores nihil obcaecati sapiente at? Eligendi, nemo!

        </div>

      </div>
    </div>
  );
};

export default Blog;