import React from 'react';
import { Link } from 'react-router-dom'

//image
import logo from "../../../../assets/images/home-page/logo.png"

function LogoImage(props) {

  return <Link className='logo' to={`${process.env.PUBLIC_URL}/`} >
    <img
      // src={`${process.env.PUBLIC_URL}/assets/images/icon/${props.logo}`} 
      src={logo}
      alt="" className="img-fluid" />
  </Link>;
}

export default LogoImage;