import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { SlideUpDownHomePage } from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterHome extends Component {

  componentDidMount() {
    var contentwidth = window.innerWidth;
    // if ((contentwidth) < 750) {
    //   SlideUpDownHomePage('footer-title-home-page');
    // } else {
    //   var elems = document.querySelectorAll(".footer-title");
    //   [].forEach.call(elems, function (elemt) {
    //     let el = elemt.nextElementSibling;
    //     //el.style = "display: block"; 
    //   });
    // }
  }


  render() {

    return (
      <footer className="footer-home">
        <div className="white-layout-home">
          <div className="container">
            {/* newsletter */}
            <section className="d-none d-lg-block small-section">
              <div className="row justify-content-center">
                <div className="col-xl-6">
                  <div className="subscribe-home">
                    <h4>Inscrivez-vous à notre Newsletter</h4>
                    <form className="form-inline subscribe-form classic-form">
                      <div className="form-group rounded border-white">
                        <input type="text" className="form-control ml-0" id="exampleFormControlInput"
                          placeholder="Votre adresse email..." />
                      </div> 
                      <button type="submit" className="btn btn-solid-home" >inscription</button>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        
        <section className="section-b-space darken-layout-home px-lg-5">
          <div className="container">
            <div className="row justify-content-lg-center align-items-lg-center footer-theme-home">
              <div className='col d-lg-flex justify-conte-lg-center align-items-lg-start pt-lg-5 pt-3'>
                {/* Section product start */}
                <div className="col d-none d-lg-block">
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>Nos produits</h4>
                    </div>
                    <div className="footer-contant d-flex footer-link">
                      <ul className='mr-5'>
                        <li><Link to={`${process.env.PUBLIC_URL}/pages/collapse`} >Flyers</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Cartes de visites</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Brochures</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Banderolles</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Branding</Link></li>
                      </ul>
                      <ul>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Roll up</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >signaletique</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Stickers</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >shopping bag</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >Autres</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Section product end */}

                {/* Section information start */}
                <div className="col d-none d-lg-block">
                  <div className="sub-title">
                    <div className="footer-title">
                      <h4>Informations</h4>
                    </div>
                    <div className="footer-contant">
                      <ul>
                        <li>A propos de</li>
                        <li> <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} ></Link>Guide d'impression</li>
                        <li> <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} ></Link>Gabarits d'impression</li>
                        <li> <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} ></Link>Template Gallery</li>
                        <li> <Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} ></Link>L'editeur en Ligne</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Section information end */}

                {/* Section contact start */}
                <div className="col">
                  <div className="sub-title">
                    <div className="footer-title text-center text-lg-left">
                      <h4>Contact</h4>
                    </div>
                    <div className="contact-info d-flex d-lg-block flex-wrap flex-lg-wrap py-3 py-lg-0">
                        <div className='important-info'><p>Takaprint Sarl</p></div>
                        <div>
                          <p>  1790 Rue Charley Eyoum</p>
                          <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/localisation1.png" alt="" /> </div>
                        <div>
                          <p> Douala</p>
                          <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/adress1.png"  alt="" /> </div>
                        <div>
                          <p> Cameroun</p>
                          <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/localisation2.png"  alt="" /> </div>
                        <div>
                          <p>  +49 231 6000 1756</p>
                          <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/telephone.png"  alt="" /></div>
                        <div>
                          <p> ventes@takaprint.cm</p>
                          <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/mail.png"  alt="" /> <a href="#"> </a></div>
                      </div>
                  </div>
                </div>
                {/* Section contact end */}
                
                {/* Section paiement start */}
                <div className="col pt-3 pt-lg-0">
                  <div className="sub-title">
                    <div className="footer-title text-center text-lg-left">
                      <h4>Mode de payment</h4>
                      <p className='d-none d-lg-block'>Notre site internet est compatible asec de nombreux mode de paiement populaires</p>
                    </div>
                    <div className="col py-2 p-lg-0">
                      <div className="payment-card-bottom-home d-flex  justify-content-center">
                        <ul className='d-flex'>
                          <li>
                            <a href="#"> <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/visa.png" alt="" /> </a></li>
                          <li>
                            <a href="#"> <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/paypal.png" alt="" /> </a></li>
                          <li>
                            <a href="#"> <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/orange_money.png" alt="" /> </a></li>
                          <li>
                            <a href="#"> <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/mtn_mobile_money.png" alt="" /></a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="footer-title important-info">
                      <p className='d-none d-lg-block'>Heure d'ouverture</p>
                      <div className='col p-0 d-flex justify-content-center justify-content-lg-start align-items-center'>
                        <p>Lundi - Vendredi</p>&nbsp;
                        <p>O9H00 - 18HhOO CET</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Section paiement end */}
              </div>

            </div>
          </div>
        </section>

        {/* social media */}
        <div className="footer-social-home py-lg-4">
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col col-lg-11'>
              <hr className='d-none d-lg-block' />
            <div className="row d-flex align-tems-center justify-content-center">
              <ul className='col px-4 pb-4 pt-lg-4 d-flex justify-content-center align-items-center'>
                <li>
                  <Link to={'https://instagram.com'}><img className="fa fa-instagram" src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/footer_insta.png" style={{ width: "40px" }} alt="" /></Link>
                </li>
                <li>
                  <Link to={'https://www.facebook.com/'} ><img className="fa fa-facebook" src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/footer_facebook.png" style={{ width: "40px" }} alt="" /></Link>
                </li>
                <li>
                  <Link to={'https://twitter.com'}><img className="fa fa-twitter" src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/footer_twitter.png" style={{ width: "40px" }} alt="" /></Link>
                </li>
                <li>
                  <Link to={'https://twitter.com'}><img className="fa fa-youtube-play" src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/footer_youtube.png" style={{ width: "40px" }} alt="" /></Link>
                </li>
              </ul>
            </div>
            <hr className='d-none d-lg-block'/>
              </div>
            </div>
          </div>
        </div>

        <div className="sub-footer-home dark-subfooter-home">
          <div className="container">
            <div className="row justify-content-between">
              <div class="col col-md-8 col-sm-12 align-items-center justify-content-center d-flex">
                <ul className='d-flex justify-content-center align-items-center flex-column flex-lg-row text-center'>
                  <li>Conditions d'utilisation</li>
                  <li>Politique de confidentialite</li>
                  <li>Mentions légales</li>
                  <li>Conditions générales de vente</li>
                </ul>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="footer-end text-center text-lg-right">
                  <p><i className="fa fa-copyright" aria-hidden="true"></i> Copyright © Takaprint &#9400; {new Date().getFullYear()}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default FooterHome;