import { stat } from "fs-extra";
import {
    FETCH_SINGLE_PRODUCT,
    CHANGE_CURRENCY,
    RECEIVE_PRODUCTS,
    ACTION_CATEGORY_PAGE
} from "../constants/ActionTypes";


const initialState = {
    products: [],
    symbol: 'FCFA ',
    product_details: []
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_PRODUCTS:
            return {
                ...state,
                products: action.products
            };
        case FETCH_SINGLE_PRODUCT:
            if (state.products.findIndex(product => product.id === action.productId) !== -1) {
                const singleItem = state.products.reduce((itemAcc, product) => {
                    return product
                }, [])
                return {
                    ...state,
                    product_details: singleItem
                };
            }

        case CHANGE_CURRENCY:
            return {
                ...state,
                symbol: action.symbol
            };
        case ACTION_CATEGORY_PAGE.ADD_PRICE_NEW_TAB:
            let getIndiceProductNewTab = state.products.findIndex(product => product.id == action.id); // get positon the product in array
            if (getIndiceProductNewTab !== -1) {
                const newArrayProduct = [...state.products] //copy array
                let IsAmountExist = false;
                newArrayProduct[getIndiceProductNewTab].priceTest2.forEach(product => {
                    if (product.quantity == action.value.quantity) {
                        IsAmountExist = true;
                        return;
                    }
                });
                if (IsAmountExist) {
                    return {
                        ...state,
                        products: newArrayProduct
                    };
                } else {
                    newArrayProduct[getIndiceProductNewTab].priceTest2 = [...newArrayProduct[getIndiceProductNewTab].priceTest2, action.value]
                    return {
                        ...state,
                        products: newArrayProduct
                    };
                }
            }
        default:
            return state;
    }
};
export default productReducer;