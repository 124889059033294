
import { ACTION_CATEGORY_PAGE } from "../constants/ActionTypes";

const initialState = {
  resumeTab: [{}],
  price: {},
  prices:{},
  checkFile: {},
  deliveryOption:{}

};

export default function setConfigReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Config
    case ACTION_CATEGORY_PAGE.SET_FORMAT:
      newState = {
        ...state,
        resumeTab: state.resumeTab.map((data) => {
          return { ...data, format: action.value }
        })
      }
      return newState || state
    case ACTION_CATEGORY_PAGE.SET_MATERIAL:
      newState = {
        ...state,
        resumeTab: state.resumeTab.map((data) => {
          return { ...data, material: action.value }
        })
      }
      return newState || state
    case ACTION_CATEGORY_PAGE.SET_PAPER:
      newState = {
        ...state,
        resumeTab: state.resumeTab.map((data) => {
          return { ...data, paper: action.value }
        })
      }
      return newState || state
    case ACTION_CATEGORY_PAGE.SET_COLOR:
      newState = {
        ...state,
        resumeTab: state.resumeTab.map((data) => {
          return { ...data, color: action.value }
        })
      }
      return newState || state
    case ACTION_CATEGORY_PAGE.SET_PAGE:
      newState = {
        ...state,
        resumeTab: state.resumeTab.map((data) => {
          return { ...data, page: action.value }
        })
      }
      return newState || state
    case ACTION_CATEGORY_PAGE.SET_FINITION:
      newState = {
        ...state,
        resumeTab: state.resumeTab.map((data) => {
          return { ...data, finition: action.value }
        })
      }
      return newState || state
    case ACTION_CATEGORY_PAGE.SET_BOOK_BINDING:
      newState = {
        ...state,
        resumeTab: state.resumeTab.map((data) => {
          return { ...data, book: action.value }
        })
      }
      return newState || state
    // prices
    case ACTION_CATEGORY_PAGE.SET_PRICES:
      newState = {
        ...state,
        prices: action.value
      }
      return newState || state
    //check file
    case ACTION_CATEGORY_PAGE.SET_CHECK_FILE:
      newState = {
        ...state,
        checkFile: action.value
      }
      return newState || state
      case ACTION_CATEGORY_PAGE.SET_ADD_DELIVERY_OPTION:
        newState = {
          ...state,
          deliveryOption: action.value
        }
        return newState || state
    default:
  }
  return state;
}