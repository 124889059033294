import React, {Component } from 'react';

//react-tabs
import 'react-tabs/style/react-tabs.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//react-router
import AnchorLink from 'react-anchor-link-smooth-scroll'


// costum components ----------------------------------start
// Button component
const MyButton = ({ title, type, componentCall=null }) => (
  <div className="wrapper-btn pt-3">
    {
      componentCall == null ? 
      <AnchorLink  className={`btn ${type}`} href='#configuration-sections'>{title}</AnchorLink>
      :
      <button className={`btn ${type}`} type="submit">{title} </button>
    }
  </div>
  
)
//form component
const MyformComment = ({ nameOfProduct, titleBtn, typeBtn }) => (
  <>
    <form className="theme-form mt-3 p-4 bg-white">
      <div className="form-row">
        <div className='col-lg-9'>
          <div className="col-md-12">
            {/* title comment form */}
            <div className='title-comment-form pb-3'>
              <span className='d-block' htmlFor="title">Vous commentez:</span>
              <strong className='d-block'>{nameOfProduct}</strong>
            </div>
            <input type="text" className="form-control" id="name" placeholder="Enter Your name" required />
          </div>
          <div className="col-md-12">
            <label htmlFor="name">Pseudo</label>
            <input type="text" className="form-control" id="name" placeholder="Enter Your name" required />
          </div>
          <div className="col-md-12">
            <label htmlFor="review">Résumé</label>
            <input type="text" className="form-control" id="review" placeholder="Enter your Review Subjects" required />
          </div>
          <div className="col-md-12">
            <label htmlFor="review">Commentaire</label>
            <textarea className="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
          </div>
          <div className='col'>
            <MyButton
              componentCall="form"
              type={typeBtn}
              title={titleBtn}
            />
          </div>
        </div>
      </div>
    </form>
  </>
)
// costum components ----------------------------------end

class MyTabDetailsProduts extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { item } = this.props;
    return (
      <section className="my-tab-product configu m-0 p-0">
        <Tabs className="tab-content nav-material">
          {/* tabs nav */

          /* Label */}
          <TabList className="nav nav-tabs nav-material">
            {
              item.details.map((elem, index) => (
                <Tab key={index} className="nav-item">
                  <span className="nav-link">
                    <i className="icofont icofont-ui-home"></i>{elem.title} </span>
                  <div className="material-border"></div>
                </Tab>
              ))
            }
          </TabList>
          {/* tabs content ---------------------------------------------start*/
          
            /* content tab */
            item.details.map((elem, index) => (
              <TabPanel key={index} className="tab-pane fade show active bg-white mt-1">
                {elem.content !== null ?
                  <div className='p-4'>
                    <p className=" p-0">
                      {elem.content}
                    </p>
                    <MyButton
                      type={'rounded'}
                      title={elem.btnTitle} />
                  </div> :
                  <MyformComment
                    typeBtn={'outlined'}
                    titleBtn={elem.btnTitle}
                    nameOfProduct={item.name} />
                }
              </TabPanel>
            ))
          }
           {/* tabs content ---------------------------------------------end*/}
        </Tabs>
      </section>
    )
  }
}

export default MyTabDetailsProduts;