import React from 'react';
//router
import { Link } from 'react-router-dom';





// render each product
const MyCardComponent = ({ img, name, price, category, id = 0 }) => {

  return (
    <li className=' list col-6 sol-sm-5 col-md-4 col-lg-3 d-block pt-lg-0 px-1 pb-0 pt-1 item product'>
      <div className='menu-home product-item-info product-item '>
        <Link to={`${process.env.PUBLIC_URL}/products/${id}`}>
          {/* section image */}
          <div className='product-item-photo'>
            <img className='product-image-photo default_image w-100' src={`${img}`} alt="" />
          </div>
          </Link>
          {/* sectoin name */}
         
          <div className='details product-item-details'>
          <Link to={`${process.env.PUBLIC_URL}/products/${id}`}>
            <strong className='product-item-name'>
              <p className='product-item-link m-0'>{name}</p>
            </strong>
            </Link>
            {/* section price */}
            <div className='price-box'>
              <span className='price-container'>
                <span className='price-wrapper '> &nbsp;
                  <span className='date-price'>à partir de </span> <span className='price'>{price} FCFA</span>
                </span>
              </span>
            </div>
          </div>
       

      </div>
    </li>
  )
}

export default MyCardComponent;