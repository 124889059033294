import React from 'react';

export const RenderItem = ({ title, bgImage, addClass = '' }) => {
  return (
    <div className={`col-12 col-md-4 col-lg-4 ${addClass}`}>
      <div className='justify-content-center'>
        <div className="position-relative service-card-home">
          <div className={`position-absolute top-0 bottom-0 bg-image ${bgImage}`} />
          <div className="content-text py-2 px-3" >
            <a href="#">{title}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

const BannerLogo = () => {
  return (
    <div className="container boxcontainer ">
    <div className="row justify-content-center align-items-center py-1 company-logo">
      <div className='imgbox11'>
        <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/firstone.png" />
      
      </div>
      <div className='imgbox11'>
        <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/takaprint_firstlogo.png"  />
    
      </div>
      <div className='imgbox11'>
        <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/firstone.png" />
       
      </div>
      <div className='imgbox11'>
        <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/print_logo.png" />
     
      </div>
      <div className='imgbox11'>
        <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/firstone.png" />
       
      </div>
      <div className='imgbox11'>
        <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/gtic_logo.png"/>
       
      </div>
      <div className='imgbox11'>
        <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/firstone.png"/>
       
      </div>
    </div>
  </div>
  )
}

export default BannerLogo;