import React, { useEffect } from 'react';
import Slider from 'react-slick';

//background Image
import { Textimonial } from '../../services/script';

//constant
import {STRING_TITLE_TESTIMONIAL, STRING_TESTIMONIAL} from '../../constants/General'

const Items = [1, 2, 3, 4, 5, 6, 7, 8, 9]
const StartIocn = [1, 2, 3]
export const RenderSliderItem = ({
  title,
  subTitle,
  bgImage,
  addClass,
  bColor,
}) => {
  return (
    <div className={`home ${addClass}`} style={{ backgroundImage: `url(${bgImage})` }}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="slider-contain">
              <div>
                <h1 style={{ fontSize: "50px" }}>{title}</h1>
                <h3 className="mt-2" style={{ fontSize: "15px", color: "black" }}>{subTitle}</h3>
                <a href="#" className="btn btn-solid" style={{ background: `${bColor}`, borderColor: `${bColor}` }}>Commander</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Testimonial = () => {

  return (
    <div>
      <section className="container-testimonial section-b-space addtocart_count ratio_square">
        <div className="container ">
          <div className="row ">
            <div className="col">
              <div className="titleTestimonial">
                <h2 className="title-inner">{STRING_TITLE_TESTIMONIAL}</h2>
              </div>
              <Slider {...Textimonial} className="testimonial-slider">
                {STRING_TESTIMONIAL.map((testimonial, index) =>
                  <div key={index}>
                    <div className="container h-100">
                      <div className="row h-100">
                        <div className="w-80 h-100 d-flex justify-content-between align-items-center">
                          <div className='col h-100 mx-3 p-2 content-single-testimonial center-slider'>
                            <div className='text-left name-testimonial'>
                              <p>{testimonial.title}</p>
                            </div>
                            {/* <div>
                                {StartIcon.map( (icon, index) => {
                                  <i key={index} className="fa fa-star"></i>
                                })} 
                              </div> */}
                            <div className='description-textimonial'>
                              <p>{testimonial.description}</p>
                            </div>
                            <div className='d-flex align-items-center legende-textimonial'>
                              <img src={testimonial.image} alt="" width={28} />
                              <span className='inline-block mx-2'> - </span>
                              <p className='m-0'>{testimonial.legende} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                }
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Testimonial

