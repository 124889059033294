import React, { Component } from 'react';
import Breadcrumb from "../../common/breadcrumb";
import ProductItem from '../common/product-item';
import { translate } from 'react-redux-multilingual/lib/utils';

// import custom Components
import { Link } from 'react-router-dom';
import { addToCart, addToCartUnsafe, addToWishlist } from '../../../actions';
import { connect } from "react-redux";
import MyBreadcrumb from '../../common/mybreadcrumb';
import relatedProduct from '../../common/related-product';




const Category = ({ item, productByCategory }) => {

  const RenderProductItem2 = ({ img, name, price, category, id }) => (
    <li className=' item product product-item mr-lg-2 categories'>
      <div className='menu-home2 product-item-info'>

        <Link to={`${process.env.PUBLIC_URL}/products/${id}`}>
          {/* section image */}
          <div className='product-item-photo'>
            <img className='product-image-photo default_image w-100' src={`${img}`} alt="" />
          </div>
        </Link>
        {/* sectoin name */}

        <div className='details product-item-details'>
          <Link to={`${process.env.PUBLIC_URL}/products/${id}`}>
            <strong className='product-item-name'>
              <p className='product-item-link m-0'>{name}</p>
            </strong>
          </Link>
          {/* section price */}
          <div className='price-box'>
              <span className='price-container'>
                <span className='price-wrapper '> &nbsp;
                  <span className='date-price'>à partir de </span> <span className='price'>{price} FCFA</span>
                </span>
              </span>
            </div>
        </div>
      </div>
    </li>
  )

  return (
    <div>
      <MyBreadcrumb title={item.category} />

      {/*Collection section*/}

      {(item.name) ?
        <section className="collection section-b-space">
          <div className="container">
            <div className='category_heading'>
              <h2>Sélectionnez Le Type de {item.category}</h2>
            </div>
            <div className="row partition-collection">
              <div className="col-lg-12 col-md-6 item2">
                <div className="collection-block">
                  {
                    productByCategory.list.map((singleProduct, index) => (
                      <RenderProductItem2
                        id={singleProduct.id}
                        key={index}
                        name={singleProduct.name}
                        img={singleProduct.image}
                        price={singleProduct.salePrice}
                        category={productByCategory.list.category}
                      />
                    ))
                  }
                </div>
              </div>


            </div>

          </div>
        </section>

        :
        <section className='collection section-b-space'>
          <div className='container'>
            <p className='category_heading'>Nous ne pouvons pas trouver de produits correspondants à la sélection.</p>
          </div>
        </section>

      }

    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  let productName = ownProps.match.params;
  let productByCategory = []

  let category_one = {
    list: []
  }
  let category_two = {
    list: []
  }
  let category_three = {
    list: []
  }
  let category_four = {
    list: []
  }
  let category_five = {
    list: []
  }
  let category_six = {
    list: []
  }
  let category_seven = {
    list: []
  }
  let category_eight = {
    list: []
  }
  let category_night = {
    list: []
  }
  let category_ten = {
    list: []
  }
  let category_eleven = {
    list: []
  }
  state.data.products.map(singleProduct => {
    singleProduct.category.map((category) => {
      switch (category) {
        case "Meilleures ventes":
          category_one['category'] = category;
          category_one.list.push(singleProduct);
          productByCategory.push(category_one)
          break;
        case "Offres":
          category_two['category'] = category;
          category_two.list.push(singleProduct);
          productByCategory.push(category_two)
          break;
        case "Carte de visites":
          category_four['category'] = category;
          category_four.list.push(singleProduct);
          productByCategory.push(category_four)
          break;
        case "Flyers":
          category_five['category'] = category;
          category_five.list.push(singleProduct);
          productByCategory.push(category_five)
          break;
        case "Carterie":
          category_six['category'] = category;
          category_six.list.push(singleProduct);
          productByCategory.push(category_six)
          break;
        case "Brochures":
          category_seven['category'] = category;
          category_seven.list.push(singleProduct);
          productByCategory.push(category_seven)
          break;
        case "Bloc notes":
          category_eight['category'] = category;
          category_eight.list.push(singleProduct);
          productByCategory.push(category_eight)
          break;
        case "Calendrier":
          category_night['category'] = category;
          category_night.list.push(singleProduct);
          productByCategory.push(category_night)
          break;
        case "Materiel de bureau":
          category_ten['category'] = category;
          category_ten.list.push(singleProduct);
          productByCategory.push(category_ten)
          break;
        case "Affiches":
          category_eleven['category'] = category;
          category_eleven.list.push(singleProduct);
          productByCategory.push(category_eleven)
          break;

        default:
          break;
      }
    })
    return productByCategory
  })
  return {
    productName,
    productByCategory: productByCategory.find(product => product.category == productName.name),
    item: state.data.products.find(el => el.category == productName.name),
    products: state.data.products,
    symbol: state.data.symbol

  }
}


export default connect(mapStateToProps)(Category); 
