import React from 'react';
import Slider from 'react-slick';

//background Image
import bg1 from "../../assets/images/home-banner/45.png";
import bg3 from "../../assets/images/home-banner/46.png";
import bg2 from "../../assets/images/home-banner/47.png";

export const RenderSliderItem = ({
  title,
  subTitle,
  bgImage,
  addClass,
  bColor,
}) => {
  return (
    <div>
      <div className={`home ${addClass}`} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="slider-contain">
                <div>
                  <h1 style={{ fontSize: "50px" }}>{title}</h1>
                  <h3 className="mt-2" style={{ fontSize: "15px", color: "black" }}>{subTitle}</h3>
                  <a href="#" className="btn btn-solid" style={{ background: `${bColor}`, borderColor: `${bColor}` }}>Commander</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SlideComponent = () => {
  return (
    <>
      <section className="p-0 small-slider">
        <Slider className="slide-1 home-slider">
          {/* <RenderSliderItem
            title={'CARTE DE VISITE CLASSIC'}
            subTitle={`dans l'impression en ligne`}
            bgImage={bg1}
            addClass={'home45'}
            bColor={'#34aea9'}
          /> */}
          <div>
            <div className="home home45" style={{ backgroundImage: `url(${bg1})` }}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="slider-contain">
                      <div>
                        <h1 style={{ fontSize: "50px" }}>CARTE DE VISITE CLASSIC</h1>
                        <h3 className="mt-2" style={{ fontSize: "15px", color: "black" }}>dans l'impression en ligne</h3>
                        <a href="#" className="btn btn-solid" style={{ background: "#34aea9", borderColor: "#34aea9" }}>Commander</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="home home46" style={{ backgroundImage: `url(${bg2})` }}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="slider-contain">
                      <div>
                        <h1 style={{ fontSize: "50px" }}>TEXTILE</h1>
                        <h3 className="mt-2" style={{ fontSize: "15px", color: "black" }}>dans l'impression en ligne</h3>
                        <a href="#" className="btn btn-solid" style={{ background: "#d83796", borderColor: "#d83796" }}>Commander</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="home home46" style={{ backgroundImage: `url(${bg3})` }}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="slider-contain">
                      <div>
                        <h1 style={{ fontSize: "50px" }}>PACKAGING</h1>
                        <h3 className="mt-2" style={{ fontSize: "15px", color: "black" }}>texte Lorem ipsum dolor sit amet consectetur, adipisicing elit.</h3>
                        <a href="#" className="btn btn-solid" style={{ background: "#34aea9", borderColor: "#34aea9" }}>Commander</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
    </>
  )
}
export default SlideComponent