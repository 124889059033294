import { List } from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
// import App from '../app';
// import Accordion from './Accordion';
import App from './collapselogique';

const Accordion2 = () => {

  const EpandAll = () => {
    const open = document.querySelector(".accordion")
    console.log(open)
    const item = open.querySelector('.accordion-content');
    console.log('item', item)
    item.classList.add('Expan')
    // item ?  isActive ? '-' : '+'
  };

  const [isActive, setIsActive] = useState(false);

 

  const accordionData = [
    {
      title: 'Section 1',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    },
    {
      title: 'Section 2',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`
    },
    {
      title: 'Section 3',
      content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`
    }
  ];


  return (
    <div>
      <div class="faqtopbanner">
        <div class="faq_mainbox">
          <h1>Faq</h1>
          <div class="faq_searchbox block-search">
            <input type="text" value="paiement" name="faq_search" placeholder="dustry's standard dummy text eve" />
            <div class="actions">
              <button class="btn button action search"></button>
            </div>
          </div>
        </div>
      </div>
      <div className="accordion">
        <div class="row faq-main-box">
          <div class="col-sm-2">
            <div class="faq_imgbox"><div class="faq_imgboxe"><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/book-icon.png" alt="" />
              <div class="faq_para" data-categoryid="1" data-category="DONNEES D'IMPRESSION"><p>DONNEES<br />
                D'IMPRESSION</p></div>
            </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="faq_imgbox"><div class="faq_imgboxe"><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/paiement_s_curis__1.png" alt="" />
              <div class="faq_para" data-categoryid="2" data-category="PAIEMENT"><p>PAIEMENT</p></div></div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="faq_imgbox"><div class="faq_imgboxe"><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/livraison_gratuite_1.png" alt="" />
              <div class="faq_para" data-categoryid="3" data-category="LIVRAISON"><p>LIVRAISON</p></div></div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="faq_imgbox"><div class="faq_imgboxe"><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/user-icon.png" alt="" />
              <div class="faq_para" data-categoryid="4" data-category="COMPTE CLIENT"><p>COMPTE <br />
                CLIENT</p>
              </div>
            </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="faq_imgbox"><div class="faq_imgboxe"><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/_diteur_en_ligne_1.png" alt="" />
              <div class="faq_para" data-categoryid="5" data-category="S.A.V"><p>S.A.V</p></div>
            </div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="faq_imgbox"><div class="faq_imgboxe"><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/pen-part.png" alt="" />
              <div class="faq_para" data-categoryid="6" data-category="EDITION EN LIGNE"><p>EDITION <br />
                EN LIGNE</p>
              </div>
            </div>
            </div>
          </div>

        </div>
        <div class="faq-acod-title">
          <h1>Question relatives aux donnees d'impression</h1>
          <div class="faq-tbtn">
            <button href="" className='expandall' onClick={() => EpandAll()}>Expan All</button>
            <button href="" className='collapsall'>Collaps All</button>
          </div>
        </div>
        <div className='group-title group-titlenew' >DONNEES D'IMPRESSION</div>
        {accordionData.map(({ title, content }) => (
          <App title={title} content={content} />
        ))}
         <div className='group-title group-titlenew' >PAIEMENT</div>
         {accordionData.map(({ title, content }) => (
          <App title={title} content={content} />
        ))}
         <div className='group-title group-titlenew' >LIVRAISON</div>
         {accordionData.map(({ title, content }) => (
          <App title={title} content={content} />
        ))}
           <div className='group-title group-titlenew' >COMPTE CLIENT</div>
         {accordionData.map(({ title, content }) => (
          <App title={title} content={content} />
        ))}
           <div className='group-title group-titlenew' >S.A.V</div>
         {accordionData.map(({ title, content }) => (
          <App title={title} content={content} />
        ))}
           <div className='group-title group-titlenew' >EDITION EN LIGNE</div>
         {accordionData.map(({ title, content }) => (
          <App title={title} content={content} />
        ))}

      </div>
    </div>
  );
};

export default Accordion2;