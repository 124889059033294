import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual';
import MyTabDetailsProduts from '../../../../components/products/mySingleProduct/my-tab-details-products';

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navClose: { right: '0px' }
    }
  }

  componentWillMount() {
    if (window.innerWidth < 750) {
      this.setState({ navClose: { right: '-410px' } })
    }
    if (window.innerWidth < 1199) {
      this.setState({ navClose: { right: '-300px' } })
    }
  }

  openNav() {
    console.log('open')
    this.setState({ navClose: { left: '0px' } })
  }
  closeNav() {
    this.setState({ navClose: { right: '-410px' } })
  }

  onMouseEnterHandler() {
    if (window.innerWidth > 1199) {
      document.querySelector("#main-menu").classList.add("hover-unset");
    }
  }

  handleSubmenu = (event) => {
    if (event.target.classList.contains('sub-arrow'))
      return;

    if (event.target.nextElementSibling.classList.contains('opensubmenu'))
      event.target.nextElementSibling.classList.remove('opensubmenu')
    else {
      document.querySelectorAll('.nav-submenu').forEach(function (value) {
        value.classList.remove('opensubmenu');
      });
      document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
      event.target.nextElementSibling.classList.add('opensubmenu')
    }
  }

  handleMegaSubmenu = (event) => {
    if (event.target.classList.contains('sub-arrow'))
      return;

    if (event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
      event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
    else {
      document.querySelectorAll('.menu-content').forEach(function (value) {
        value.classList.remove('opensubmegamenu');
      });
      event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
    }
  }

  render() {
    const { translate } = this.props;
    return (
      <div>
        <div className="main-navbar">
          <div id="mainnav" >        
            <div className="toggle-nav"  >
           
            {/* <i className='fa fa-bars menu-amburg' onClick={this.openNav.bind(this)}></i>
            <i className='fa fa-search menu-search'></i> */}
              {/* <i className="fa fa-bars sidebar-bar"></i> */}
            </div>
           
            <ul className="nav-menu" style={this.state.navClose}>
              {/* <MyTabDetailsProduts /> */}
              <li className="back-btn" onClick={this.closeNav.bind(this)} >
                {/* <div className="mobile-back text-right"> 
                  <span >X</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div> */}
              </li>
              <li>
                <Link to={`${process.env.PUBLIC_URL}/collection/Carte de visites`} className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                  {/* {translate('products')} */} Carte de visites
                  {/* <span className="sub-arrow"></span> */}
                </Link>
              </li>
              <li className="mega-menu">
                <Link to={`${process.env.PUBLIC_URL}/collection/Flyers`} className="nav-link dropdown" onClick={(e) => this.handleSubmenu(e)}>
                  {/* {translate('features')} */} Flyers
                  {/* <span className="sub-arrow"></span> */}
                </Link>

              </li>
     
              <li >
                <Link to={`${process.env.PUBLIC_URL}/collection/Affiches`} className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                  {/* {translate('blog')} */} Affiches
                  {/* <span className="sub-arrow"></span> */}
                </Link>
              </li>
              <li >
                <Link to={`${process.env.PUBLIC_URL}/pages/collapse`} className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                  {/* {translate('blog')} */} Faq
                  {/* <span className="sub-arrow"></span> */}
                </Link>
              </li>
              <li >
                <Link to={`${process.env.PUBLIC_URL}/pages/blog`} className="nav-link" onClick={(e) => this.handleSubmenu(e)}>
                  {/* {translate('blog')} */} Blog
                  {/* <span className="sub-arrow"></span> */}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}


export default withTranslate(NavBar);