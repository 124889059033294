import React from "react";
import Slider from 'react-slick';

const SliderBannerPrint = () => {
  return (
    <div className='col d-md-none'>
      <Slider className='banner-print-home-slider'>
        <div className="col col-md-6">
          <div className="slider-contain-print d-flex justify-content-center align-items-center flex-column">
            <span className=""><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage1.png" alt="" style={{ height: "100px" }} /></span>
            <span className="image-print"><img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage1.png" alt="" style={{ height: "100px" }} /></span>
            <h4>Choisissez votre produit</h4>
            <p>simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the</p>
          </div>
        </div>
        <div className="col col-md-6">
          <div className="slider-contain-print d-flex justify-content-center align-items-center flex-column">
            <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage2.png" alt="" style={{ height: "100px" }} />
            <h4>Charger et/ou configurer</h4>
            <p>simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the</p>
          </div>
        </div>
        <div className="col col-md-6">
          <div className="slider-contain-print d-flex justify-content-center align-items-center flex-column">
            <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage3.png" alt="" style={{ height: "100px" }} />
            <h4>Paiement par carte ou mobile</h4>
            <p>simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the</p>
          </div>
        </div>
        <div className="col col-md-6">
          <div className="slider-contain-print d-flex justify-content-center align-items-center flex-column">
            <img src="http://takaprint-m2-sao076.your-printq.com/pub/media/wysiwyg/hoverimage4.png" alt="" style={{ height: "100px" }} />
            <h4>Transport rapide</h4>
            <p>simply dummy text of the printing and typesetting in- dustry. Lorem Ipsum has been the industry's stande the</p>
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default SliderBannerPrint