import React from 'react';
//router
import { Link } from 'react-router-dom';

// render each product
const MyCardComponent = ({ img, name, size, id = 0, onClick }) => {
  return(
    <li 
    onClick={() => onClick() }
    className='col-6 sol-sm-5 col-md-3 col-lg-2 d-block pt-lg-0 px-1 pb-0 pt-1 item product'>
      <div className='product-item-info product-item'>
        {/* section image */}
        <div className='product-item-photo'>
          <img className='w-100' src={`${img}`} alt="" />
        </div>
        {/* section size and name */
          name !== '' ?
            <div className='details-items-config-box p-3'>
              {/* sectoin size */}
              <div className='product-item-size-wrapper'>
                <strong className='product-item-size'>
                  <span className='product-size'>{size}</span>
                </strong>
              </div>
              {/* name */}
              <div className='name-product-wrapper'>
                <span className='name-product'>{name}</span>
              </div>
            </div> : null
        }
      </div>
    </li>
  )
}

export default MyCardComponent;