import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

//react-tabs
import 'react-tabs/style/react-tabs.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import {useLocation } from 'react-router-dom'

import MyCard from './my-card';

import {
  setConfigPage,
  setConfigFormat,
  setConfigPaper,
  setConfigColor,
  setConfigFinition,
  setConfigMaterial,
  setConfigBookBinding
} from '../../../actions/'

import {scroller } from 'react-scroll'


const MyTabDetailsConfigurationProduts = ({ dispatch, product, ownProps }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [lengthTab, setLengthTab] = useState(0)
  const [activeIndex, setActiveIndex] =useState([])
  const [lastIndex, setLastIndex] =useState([0])
  const [title, setTitle] = useState()

  useEffect(() => {
    // const {test} = useLocation()
  }, [])



  const setConfig = (title, config, index) => {
    // console.log('do the naviagation here', index)
    setLengthTab(product.configuration.length);
    if (product.configuration.length - 1 >= index + 1) {
      setActiveIndex( oldArray => [...oldArray, tabIndex])
      if(lastIndex.findIndex(oldIndex => oldIndex == index) == -1) setLastIndex(oldArray => [...oldArray, index ]);
      setTabIndex(index + 1);
    } 
    if (lastIndex.length == product.configuration.length - 1) {
      scroller.scrollTo('price-recap', {
        duration: 1500,
        delay: 100,
        smooth: true,
      })
    }
    switch (title) {
      case 'Format':
        dispatch(setConfigFormat(`${config.size} | ${config.desc}`))
        break;
      case 'Materiaux':
        dispatch(setConfigMaterial(config.info))
        break;
      case 'Type de papier':
        dispatch(setConfigPaper(config.info))
        break;
      case 'Coloris':
        dispatch(setConfigColor(config.info))
        break;
      case 'Pages':
        dispatch(setConfigPage(config.info))
        break;
      case 'Finitions':
        dispatch(setConfigFinition(`${config.size} | ${config.desc}`))
        break;
      case 'Book Binding':
        dispatch(setConfigBookBinding(`${config.size} | ${config.desc}`))
        break;
      default:
        console.log('error dans le switch case my-details-configuration')
        break;
    }
  }
  const controlIndex = (index, lastindex) => {
    setTabIndex(index) 
  }
  return (
    <div className="my-tab-product configu m-0 p-0">
      <Tabs selectedIndex={tabIndex} onSelect={(index, lastindex) => controlIndex(index, lastindex)} className="tab-content nav-material">
        {/* tabs nav */

          /* Label */}
        <TabList className="nav nav-tabs nav-material">
          {
            product.configuration.map((config, index) => {
              return(
              <Tab key={index} className="nav-item">
                <span className={lastIndex.find(lastindex => lastindex == index) == index ? 'nav-link-visited': 'nav-link'}>
                  <i className="icofont icofont-ui-home"></i>{index + 1}.{config.title} </span>
                <div className="material-border"></div>
              </Tab>)
              })
          }
        </TabList>
        {/* tabs content ---------------------------------------------start*/

          /* content tab */
          product.configuration.map((config, index) => {
            console.log(typeof (config.data));
            return(
              <TabPanel key={index.toString()} className="tab-pane fade show active bg-white">
              <div className='container'>
                <div className='row'>
                  <div className='col py-4 p-lg-5'>
                    <p className='mb-4 subtitle-tabs'>{index + 1}.Choississez votre {config.title}</p>
                    <div className='d-flex flex-wrap'>
                      {
                        config.data.map((elem) => (
                          <MyCard
                            onClick={() => setConfig(config.title, elem, index)}
                            key={index.toString()}
                            size={elem.size}
                            img={elem.image}
                            name={elem.desc}
                            id={index + 1}
                          /> 
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            )
          })
        }
        {/* tabs content ---------------------------------------------end*/}
      </Tabs>

      {/* mobile view */}
     
      <div className='pageoptions-wrapper pageoptions-top'>
        <div className='pageoption-tab-wrapper format active' id='format'>
          <div className='pageoption-tab'>
            <span childrenpo-title> 
         
             </span>
          </div>
        </div>

      </div>
    </div>
  )

}


const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapDispatchToProps)(MyTabDetailsConfigurationProduts);

