import React, { useState } from 'react';
import './styles/collapse.css'



const App = ({ title, content,name }) => {

  const [isActive, setIsActive] = useState(false);

  const EpandAll = () => {
    const open = document.querySelector(".accordion-content")
    console.log(open)
  };

  return (
    
    <div className="accordion-item">
      {/* <div className='accordion-name'>{name}</div> */}
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default App;