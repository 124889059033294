import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../common/breadcrumb";
import { removeFromWishlist } from '../../actions'
import { getCartTotal } from "../../services";
// new import
import images from '../pages/images/logo4.png'


const Paiement = [
    {
        id: 1,
        name: "Carte Visa",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM6hgmDcT26NzPxospzXbefBlMsJJgDZ3YOVa9_VdhVg&s",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis sunt natus similique, nesciunt incidun"
    },
    {
        id: 2,
        name: "Carte Papayl",
        image: "https://www.paypalobjects.com/digitalassets/c/website/logo/full-text/pp_fc_hl.svg",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis sunt natus similique, nesciunt incidun"
    },
    {
        id: 3,
        name: "Monetbill",
        image: "https://media-exp1.licdn.com/dms/image/C4E0BAQE-xK_D88N3dg/company-logo_100_100/0/1519868297465?e=1674086400&v=beta&t=NsAN3b_-mvG2_W_wWgm__bw05PDbTw2vtATpybUj-Uo",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis sunt natus similique, nesciunt incidun"
    },
    {
        id: 4,
        name: "Check/Money Order",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQM6hgmDcT26NzPxospzXbefBlMsJJgDZ3YOVa9_VdhVg&s",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis sunt natus similique, nesciunt incidun"
    }
];

class Paiementhome extends Component {




    checkhandle(value) {
        this.setState({
            payment: value
        })
    }

    StripeClick = () => {

        if (this.validator.allValid()) {
            alert('You submitted the form and stuff!');

            var handler = (window).StripeCheckout.configure({
                key: 'pk_test_glxk17KhP7poKIawsaSgKtsL',
                locale: 'auto',
                token: (token: any) => {
                    console.log(token)
                    this.props.history.push({
                        pathname: '/order-success',
                        state: { payment: token, items: this.props.cartItems, orderTotal: this.props.total, symbol: this.props.symbol }
                    })
                }
            });
            handler.open({
                name: 'Multikart',
                description: 'Online Fashion Store',
                amount: this.amount * 100
            })
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }

    render() {
        const { cartItems, symbol, total } = this.props;

        console.log(Paiement);

        // Paypal Integration
        const onSuccess = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push({
                pathname: '/order-success',
                state: { payment: payment, items: cartItems, orderTotal: total, symbol: symbol }
            })

        }

        const onCancel = (data) => {
            console.log('The payment was cancelled!', data);
        }

        const onError = (err) => {
            console.log("Error!", err);
        }

        const client = {
            sandbox: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
            production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
        }


        return (
            <div>

                {/*SEO Support*/}
                <Helmet>
                    <title>Paiement</title>
                    <meta name="description" content="Takaprint." />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={'Checkout'} />

                <section className="section-b-space">
                    <div className="container padding-cls">
                        <div className="checkout-page">
                            <div className="checkout-form">
                                <form>
                                    <div className="checkout row">
                                        <div className="col-lg-6 col-sm-12 col-xs-12 paiementhome">
                                            <div className="checkout-details">

                                                <div className="payment-box">
                                                    <div className='container'>
                                                        <div className='row-cols-6 paiementbox d-flex'>
                                                            {
                                                                Paiement.map((list, index) => {

                                                                    console.log(list)
                                                                    return (
                                                                        <div key={index} className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 paiementicon'>
                                                                            <div className='design'>
                                                                                <h4 className='paiementtitle'>{list.name}</h4>
                                                                                <div className=' bodypaiement d-flex'>
                                                                                    <div className='bodypaiement-img'> <img src={list.image} height={'70px'} width={'70px'} /></div>
                                                                                    <div className='bodypaiement-description'><p>{list.description}</p></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-xs-12 shipping">
                                            <div className='cart-summary'>
                                                <div className='block shipping'>
                                                    <div className='title'>
                                                        <strong id='block-shipping-heading'>Recapitulatif de la commande</strong>

                                                    </div>
                                                    <div className='block-summary'>
                                                        <table className="table cart-table table-responsive-md">
                                                            <tfoot>
                                                                <tr>
                                                                    <td>Sous-total </td>
                                                                    <td><h2>{symbol} {total} </h2></td>

                                                                    {/* <td>Livraison :</td><br />
                                                        <td><h2> 0 FCFA </h2></td> */}
                                                                </tr>

                                                                <tr>
                                                                    <td>Total de la commande</td>
                                                                    <td><h2> {symbol} {total} </h2></td>
                                                                </tr>

                                                            </tfoot>
                                                        </table>
                                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <Link to={`${process.env.PUBLIC_URL}/carthome`}>
                                                                <input className='d-block w-100  validemoi' type="submit" name="submit" value='Editer' onChange={this.setStateFromInput} />
                                                            </Link>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                            <div className='cart-summary ship'>
                                                <div className='block shipping'>
                                                    <div className='title'>
                                                        <strong id='block-shipping-heading'>Mode de livraison</strong>

                                                    </div>
                                                    <div className='block-summary'>
                                                        <table className="table cart-table table-responsive-md">
                                                            <tfoot>


                                                                <tr>
                                                                    <td>Frais de livraison inclus</td>
                                                                    <td><h2> Oui </h2></td>
                                                                </tr>

                                                            </tfoot>
                                                        </table>
                                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <Link to={`${process.env.PUBLIC_URL}/carthome`}>
                                                                <input className='d-block w-100  validemoi' type="submit" name="submit" value='Editer' onChange={this.setStateFromInput} />
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                            <div className='cart-summary ship'>
                                                <div className='block shipping'>
                                                    <div className='title'>
                                                        <strong id='block-shipping-heading'>Adresse de livraison</strong>

                                                    </div>
                                                    <div className='block-summary'>
                                                        <table className="table cart-table table-responsive-md">
                                                            <tfoot>
                                                                <tr>
                                                                    <td>Freddy Kamga</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kotto bonamoussadji</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Douala littoral 237</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Cameroun</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>+237 691706006</td>
                                                                </tr>
                                                                <hr />

                                                            </tfoot>
                                                        </table>
                                                        <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <Link to={`${process.env.PUBLIC_URL}/carthome`}>
                                                                <input className='d-block w-100  validemoi' type="submit" name="submit" value='Editer' onChange={this.setStateFromInput} />
                                                            </Link>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart)
})

export default connect(
    mapStateToProps,
    { removeFromWishlist }
)(Paiementhome)