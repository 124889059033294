import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Breadcrumb from "../common/breadcrumb";

class Loginhome extends Component {

    constructor(props) {
        super(props)

    }

    render() {


        return (
            <div>
                {/* <Breadcrumb title={'Login'}/> */}


                {/*Login section*/}
                <section className="login-page section-b-space container form-bg-wa">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                              <div className='block block-customer-login'>
                              <h3>Login</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" id="email" placeholder="Email"
                                                required="" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input type="password" className="form-control" id="review"
                                                placeholder="Enter your password" required="" />
                                        </div>
                                        <Link className="btn btn-solid" to={`${process.env.PUBLIC_URL}/pages/my-account`} data-lng="en">Login</Link>
                                        {/* <a href="#" className="btn btn-solid">Login</a> */}
                                    </form>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <div className='block_new_customer'>
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create A Account</h6>
                                    <ul class="login-point">
                                        <li>Produits imprimes de qualite</li>
                                        <li>Livraison offerte partout au Cameroun*</li>
                                        <li>Tarifs tout compris</li>
                                        <li>Des centaines de milliers de modeles gratuits</li>
                                        <li>Un editeur de creation en ligne gratult</li>
                                        <li>Nombreux moyens de palement securises</li>
                                    </ul>
                                    <Link  className="btn btn-solid" to={`${process.env.PUBLIC_URL}/pages/my-account`} data-lng="en">Create an Account</Link>
                                    {/* <a href="#" className="btn btn-solid">Create an Account</a> */}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                

            </div>
        )
    }
}

export default Loginhome