import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class MyBreadcrumb extends Component {
  render() {
    const { title, parent } = this.props;
    return (
      <div className="my-breadcrumb-section px-lg-5 py-0 m-0 miene">
        <div className="container1">
          <div className="row">
            <div className="col p-0 mx-lg-5">
              <nav aria-label="breadcrumb" className="theme-breadcrumb m-0">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Accueil</Link></li>
                  {parent ?
                  parent.map((elem, index) => (
                    <li className="breadcrumb-item" aria-current="page">
                    <Link to={`${process.env.PUBLIC_URL}/collection/${elem}`}>{elem} </Link>
                  </li>
                  ))
                  : ''}
                  <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MyBreadcrumb;